// @flow
import * as React from 'react';
import Toastr from './Toastr';
import { flowRight as compose } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import MasterLayout from '../lib/layout/MasterLayout';
import version from '../version';
import Sidebar from '../lib/layout/Sidebar';
import LoginPage from '../pages/auth/LoginPage';
import LoaderPage from '../lib/layout/LoaderPage';
import { connect } from 'react-redux';
import OrganizationSidebar from './OrganizationSidebar';
import { resetContentState } from '../pages/entities/content/store/contentSlice';
import {
  setCurrentScopes,
  setActiveServiceLevel,
  setCurrentOrganization,
} from '../pages/entities/organizations/store/organizationsSlice';
import locations, {
  extractPartsFromPath,
  findRouteFromPath,
  buildBreadcrumbs,
} from '../locations';
import { withOrganizationAction } from '../hoc';

import { BreadcrumbsProvider } from '../lib/BreadcrumbsProvider';
import {
  BreadcrumbItem,
  BreadcrumbItemSeparator,
  canShowBreadcrumb,
} from '../components/BreadcrumbItem';
import { auth } from '../Auth';
import * as operations from '../operations';
import { setStatesAndCountries } from '../pages/entities/locations/store/locationsSlice';
import { Outlet } from 'react-router-dom';
import withNavigate from '../hoc/withNavigate';
import withHistoryListener from '../hoc/withHistoryListener';
import { AppLayoutContext } from '../context/AppLayoutContext';

type Props = {
  data: {
    loading: boolean,
    viewer?: any,
    error?: any,
    refetch: () => void,
  },
  scope: string,
  logout: () => void,
  isLoggedIn: boolean,
  currentOrgId: String,
  navigatorLocation: {},
  isDrawerActive: boolean,
  currentScopeLabel: string,
  resetContentState: () => void,
  setLoadScope: (boolean) => void,
  navigate: (url: string) => void,
  getCurrentScopes: () => Promise<any>,
  setCurrentScopes: (props: any) => void,
  listenToHistory: (() => void) => () => void,
  setActiveServiceLevel: (props: any) => void,
  setCurrentOrganization: (props: any) => void,
  scopes:
    | 'loading'
    | Array<{
        type: string,
        url: string,
        wildcard: boolean,
        label: string,
      }>,
  children: React.Element<any>,
  permissions: string[],
  mainOrganization: string,
};

type State = {
  isScopesOpen: boolean,
  isDrawerActive: boolean,
  isSidebarActive: boolean,
};

class AppLayout extends React.Component<Props, State> {
  unlistenToHistoryChanges: () => void;
  unlistenToAuthChanges: () => void;

  state = {
    isScopesOpen: false,
    isDrawerActive: false,
    isSidebarActive: true,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      this.props.data.refetch();
    }

    if (this.props.currentOrgId !== nextProps.currentOrgId) {
      this.props.setCurrentOrganization(
        nextProps.currentOrgId,
        nextProps.currentScopeLabel
      );
      this.props.resetContentState();
      this.props.setOrgId(nextProps.currentOrgId);
      this.props.setWithActiveServiceLevel(true);
      this.setState(() => ({ isSidebarActive: true }));
    }

    if (
      this.props.currentOrgId === nextProps.currentOrgId &&
      !this.state.isScopesOpen
    ) {
      this.setState(() => ({ isSidebarActive: true }));
    }
    if (this.props.countries !== nextProps.countries) {
      this.props.setStatesAndCountries({
        states: nextProps.states,
        countries: nextProps.countries,
      });
      this.props.setActiveServiceLevel(nextProps.activeServiceLevel);
    }
  }

  onHistoryChange = () => {
    this.setState(() => ({ isScopesOpen: false }));
  };

  componentDidMount() {
    this.unlistenToHistoryChanges = this.props.listenToHistory(
      this.onHistoryChange
    );

    const wasAuthenticated = auth.isAuthenticated();
    this.unlistenToAuthChanges = auth.addOnAuthChangedListener(
      (isAuthenticated: boolean) => {
        if (wasAuthenticated && !isAuthenticated) {
          operations.logout();
        }
      }
    );

    if (this.props.scopes === 'loading') {
      this.loadCurrentScopes();
    }
  }

  componentWillUnmount() {
    this.unlistenToAuthChanges();
    this.unlistenToHistoryChanges();
  }

  onItemClicked = (item) => {
    this.setState(() => ({ isDrawerActive: false }));

    if (item.to) {
      this.props.navigate(item.to);
      return;
    }

    if (item.link) {
      window.open(item.link);
    }
  };

  toggleDrawer = () =>
    this.setState(({ isDrawerActive }) => ({
      isDrawerActive: !isDrawerActive,
    }));

  onBreadcrumbClicked = (url) => {
    this.props.navigate(url);
  };

  constructBreadcrumbs = () => {
    let { path, params } = this.props.navigatorLocation;
    let parts = extractPartsFromPath(path);
    let route = findRouteFromPath(parts.scopeId, path);
    let breadcrumbs = buildBreadcrumbs(
      route.breadcrumbs(),
      parts.scopeId,
      params
    );

    let items = breadcrumbs
      .filter((b) => canShowBreadcrumb(b.id))
      .reduce((acc, b, i, col) => {
        let isLast = i === col.length - 1;
        return [
          ...acc,
          <BreadcrumbItem
            key={b.id}
            id={b.id}
            url={b.url}
            isCurrent={isLast}
            onClick={this.onBreadcrumbClicked}
          />,
          isLast ? null : <BreadcrumbItemSeparator key={`${b.id}-separator`} />,
        ];
      }, []);

    return items;
  };

  showHomePage = () => {
    let { path } = this.props.navigatorLocation;
    let parts = extractPartsFromPath(path);

    if (!parts) {
      return;
    }

    const { scope, scopeId } = parts;

    if (scope.startsWith('org/')) {
      this.props.navigate(locations.org(scopeId).index.url());
    }
  };

  loadCurrentScopes = () => {
    this.props
      .getCurrentScopes()
      .then((scopes) => this.props.setCurrentScopes(scopes));
  };

  render() {
    if (!this.props.isLoggedIn) {
      return <LoginPage />;
    }
    let scopeParts, currentScopeType, currentScopeId;
    if (this.props.scope) {
      scopeParts = this.props.scope.split(':');
      currentScopeType = scopeParts[0];
      currentScopeId = scopeParts[1];
    }
    if (this.props.data.loading) {
      return <LoaderPage />;
    }
    if (
      !this.props.isLoggedIn ||
      !this.props.data ||
      !this.props.data.viewer ||
      !this.props.data.viewer.isLoggedIn
    ) {
      return <LoginPage {...this.props} />;
    }

    let currentUser = this.props.data.viewer.me.name;
    let currentUserId = this.props.data.viewer.me.id;
    const {
      currentScopeLabel,
      logout,
      children,
      permissions,
      mainOrganization,
      activeServiceLevel,
      isSkinDesignerEnabled,
    } =
      this.props || {};

    return (
      <MasterLayout
        version={version}
        userName={currentUser}
        onToggleScopesClick={() => {
          this.setState(({ isScopesOpen }) => ({
            isScopesOpen: !isScopesOpen,
          }));
          this.setState(({ isSidebarActive }) => ({
            isSidebarActive: !isSidebarActive,
          }));
        }}
        onLogout={logout}
        permissions={permissions}
        context={currentScopeLabel}
        onLoadScopes={this.onLoadScopes}
        onHomeClicked={this.showHomePage}
        onToggleDrawer={this.toggleDrawer}
        mainOrganization={mainOrganization}
        isScopesOpen={this.state.isScopesOpen}
        scopes={this.props.scopes || 'loading'}
        drawerActive={this.state.isDrawerActive}
        isSidebarActive={this.state.isSidebarActive}
        activeServiceLevel={activeServiceLevel}
        setCurrentOrganization={this.props.setCurrentOrganization}
      >
        <Sidebar>
          {currentScopeType === 'org' && (
            <OrganizationSidebar
              orgId={currentScopeId}
              permissions={permissions}
              onItemClicked={this.onItemClicked}
              activeServiceLevel={activeServiceLevel}
              enableWebAppBuilder={isSkinDesignerEnabled}
            />
          )}
        </Sidebar>
        <Toastr />

        <BreadcrumbsProvider breadcrumbs={this.constructBreadcrumbs()}>
          <AppLayoutContext.Provider
            value={{
              currentScopeId,
              currentScopeLabel,
              permissions,
              currentUserId,
              mainOrganization,
            }}
          >
            <Outlet />
          </AppLayoutContext.Provider>
        </BreadcrumbsProvider>
      </MasterLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const { scopes } = state.organizations;
  return { scopes };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    resetContentState: () => dispatch(resetContentState()),
    setActiveServiceLevel: (activeServiceLevel: number) =>
      dispatch(setActiveServiceLevel({ activeServiceLevel })),
    setCurrentOrganization: (id: string, name: string) =>
      dispatch(setCurrentOrganization({ id, name })),
    setCurrentScopes: (scopes: any[]) => dispatch(setCurrentScopes(scopes)),
    setStatesAndCountries: ({ states, countries }) =>
      dispatch(setStatesAndCountries({ states, countries })),
  };
};

const LoadScopeQuery = gql`
  query($scope: ScopeType!) {
    viewer {
      scope(scope: $scope) {
        label
        scope
        type
      }
    }
  }
`;

export const LoadUserQuery = gql`
  query {
    viewer {
      isLoggedIn
      me {
        id
        name
        email
        roles
        permissions
        orgId
        databaseDetails {
          timeout
        }
      }
    }
  }
`;

export default compose(
  graphql(LoadScopeQuery, {
    props: ({ ownProps, data }) => {
      const { loading } = data;
      if (data && data.viewer) {
        const currentScopeLabel = data.viewer.scope.label;
        const currentOrgId = data.viewer.scope.scope.split(':')[1];
        return {
          currentScopeLabel,
          currentOrgId,
        };
      }
      return { loading };
    },
    options: ({ scope }) => {
      return {
        skip: !scope || !scope.length,
        variables: {
          scope: scope,
        },
      };
    },
  }),
  graphql(LoadUserQuery, {
    props: ({ ownProps, data }) => {
      let userData;
      let permissions;
      let mainOrganization;
      if (data && data.viewer) {
        userData = data.viewer.me;
        permissions = userData.permissions;
        mainOrganization = userData.orgId;

        // set inactivity timeout based on settings from specific user
        const timeoutMs = userData.databaseDetails.timeout * 1000;
        auth.updateInactivityTimeout(timeoutMs);
      }
      return {
        mainOrganization,
        permissions,
        data,
        userData,
      };
    },
  }),
  withOrganizationAction,
  require('../hoc/withIdentity').withLogoutAction(),
  require('../hoc/withIdentity').withLoginState(),
  withHistoryListener(),
  withNavigate(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AppLayout);
