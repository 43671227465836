import React from 'react';
import Category from './Category';
import locations from '../locations';
import { t } from '../base/Translate';
import { enforcement } from '@drinkripples/utils-frontend';

const { SERVICE_LEVEL_ENTERPRISE, SERVICE_LEVEL_FREE } = enforcement.config;

const SUPPORT_URL = 'https://support.drinkripples.com/hc/en-us';

const OrganizationSidebar = ({
  orgId,
  permissions,
  onItemClicked,
  activeServiceLevel = SERVICE_LEVEL_FREE,
  enableWebAppBuilder,
}) => (
  <div>
    <Category
      title={t('Admin')}
      onItemClicked={onItemClicked}
      items={[
        {
          label: t('Utilities'),
          visible: permissions.includes('admin:jobs'),
          to: locations.org(orgId).Admin.utilities.url(),
        },
        {
          label: t('Users'),
          visible: permissions.includes('user:view'),
          to: locations.org(orgId).Users.index.url(),
        },
        {
          label: t('Maker Apps'),
          visible: permissions.includes('admin:apps'),
          to: locations.org(orgId).MakersApps.index.url(),
        },
      ]}
    />
    <Category
      title={t('Assets')}
      onItemClicked={onItemClicked}
      items={[
        {
          label: t(['ab8d7e39-1294-4d7e-8ef1-12e922e05f19', 'Hierarchy view']),
          visible:
            activeServiceLevel >= SERVICE_LEVEL_ENTERPRISE &&
            permissions.includes('location:manage') &&
            permissions.includes('organization:manage'),
          to: locations.org(orgId).Hierarchy.index.url(),
        },
        {
          label: t('Organizations'),
          visible:
            permissions.includes('location:manage') &&
            permissions.includes('organization:manage'),
          to: locations.org(orgId).Organizations.index.url(),
        },
        {
          label: t('Locations'),
          visible: permissions.includes('location:view'),
          to: locations.org(orgId).Locations.index.url(),
        },
        {
          label: t(['29b99330-b36a-4aa0-b7e7-7fbe3f1a4bb0', 'Devices']),
          visible: permissions.includes('machine:view'),
          to: locations.org(orgId).RippleMakers.index.url(),
        },
      ]}
    />
    <Category
      title={t('Designs')}
      onItemClicked={onItemClicked}
      items={[
        {
          label: t(['5a22598f-b451-4165-9f55-693c840e866a', 'My Library']),
          visible:
            permissions.includes('ripple:view') ||
            permissions.includes('ripple:upload'),
          to: locations.org(orgId).Ripples.library.url(),
        },
        {
          label: t(['', 'Shared Library']),
          visible: permissions.includes('ripple:view'),
          to: locations.org(orgId).SharedRipples.library.url(),
        },
        {
          label: t('My Channels'),
          visible: permissions.includes('channel:view'),
          to: locations.org(orgId).Channels.library.url(),
        },
        {
          label: t(['6ce61de0-fa51-40a6-a9a3-29a189137549', 'Shared channels']),
          visible: permissions.includes('channel:view'),
          to: locations.org(orgId).SharedChannels.library.url(),
        },
      ]}
    />
    <Category
      title={t(['596f124e-852f-414e-9bbe-d2f8b47591ed', 'Device content'])}
      onItemClicked={onItemClicked}
      items={[
        {
          label: t('Ripple Maker'),
          visible: permissions.includes('location:managecontent'),
          to: locations.org(orgId).RippleMaker.index.url(),
        },

        {
          label: t('Mobile'),
          visible: permissions.includes('webapp:managecontent'),
          to: locations.org(orgId).Mobile.index.url(),
        },
      ]}
    />
    <Category
      title="NOTIFICATIONS"
      onItemClicked={onItemClicked}
      items={[
        {
          label: 'Notifications',
          visible: permissions.includes('notifications:view'),
          to: locations.org(orgId).Notifications.index.url(),
        },
        {
          label: 'Automatic',
          visible: permissions.includes('notifications:view'),
          to: locations.org(orgId).Triggers.index.url(),
        },
        {
          label: 'Templates',
          visible: permissions.includes('notifications:templates'),
          to: locations.org(orgId).Templates.index.url(),
        },
      ]}
    />

    <Category
      title={t('65ec1651-05b2-4ba1-b87b-40b58bf1b01d', 'Tools')}
      onItemClicked={onItemClicked}
      items={[
        {
          label: t(['1c5f28de-5d4b-4089-b9e5-e3745c934134', 'My Campaigns']),
          visible:
            activeServiceLevel >= SERVICE_LEVEL_ENTERPRISE &&
            permissions.includes('campaign:manage'),
          to: locations.org(orgId).Campaigns.index.url(),
        },
        {
          label: t('cf08e175-186e-431a-8ec0-9ab95651b7f4', 'Reports'),
          visible:
            activeServiceLevel >= SERVICE_LEVEL_ENTERPRISE &&
            permissions.includes('admin:reports'),
          to: locations.org(orgId).Admin.reports.url(),
        },
        {
          label: t('Web App Builder'),
          visible: enableWebAppBuilder && permissions.includes('webapp:design'),
          to: locations.org(orgId).Skin.index.url(),
        },
        {
          label: t(['36630bd8-88bb-4aae-aa2a-bd16a63778c2', 'Survey Builder']),
          visible:
            activeServiceLevel >= SERVICE_LEVEL_ENTERPRISE &&
            enableWebAppBuilder &&
            permissions.includes('campaign:manage'), //user that can see campaign can see Survey Builder
          to: locations.org(orgId).Shoutout.index.url(),
        },
        {
          label: t(['6fac467d-4533-40e1-b3ba-27b93893606a', 'Screen Saver']),
          visible:
            activeServiceLevel >= SERVICE_LEVEL_ENTERPRISE &&
            permissions.includes('organization:manage'),
          to: locations.org(orgId).ScreenSaver.index.url(),
        },
      ]}
    />
    <Category
      title={t('Help')}
      onItemClicked={onItemClicked}
      items={[
        {
          label: t('Help Center'),
          visible: true,
          link: SUPPORT_URL,
        },
      ]}
    />
  </div>
);

export default OrganizationSidebar;
