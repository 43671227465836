import 'core-js/stable';
import './airbrake';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import './normalize.css';
import { unregister } from './registerServiceWorker';

import React from 'react';
import { createBrowserHistory } from 'history';
import { IntlProvider } from 'react-intl';
import { GraphqlProvider } from './network';
import RoutesProvider from './pages/routes';

import { ConfigProvider } from 'antd';
import { Provider as StoreProvider } from 'react-redux';
import store from './store';
import { auth } from './Auth';
import TagManager from 'react-gtm-module';
import gt4Config from './authConfig';
import theme from './theme';

const browserHistory = createBrowserHistory();

const tagManagerArgs = {
  gtmId: gt4Config.ga4MeasurementId,
};

TagManager.initialize(tagManagerArgs);
const match = /^https:\/\/([^.]+)\.coffeeripples\.com(.+)$/.exec(
  window.location
);

if (match) {
  alert(
    'You will be redirected to my.drinkripples.com\nMake sure to update your bookmarks'
  );
  let newLocation = `https://${match[1]}.drinkripples.com${match[2]}`;
  window.location = newLocation;
} else {
  if (/^\/dev\/mobile-integration\/docs/.test(window.location.pathname)) {
    import('./pages/dev/MobileApiPage').then(({ default: Component }) => {
      const container = document.getElementById('root');
      const root = ReactDOMClient.createRoot(container);
      root.render(<Component />);
    });
  } else {
    browserHistory.listen((location) => {
      auth.resetUserActivityTimeout();
    });
    const container = document.getElementById('root');
    const root = ReactDOMClient.createRoot(container);
    root.render(
      <GraphqlProvider>
        <StoreProvider store={store}>
          <IntlProvider locale="en">
            <ConfigProvider theme={theme}>
              <RoutesProvider />
            </ConfigProvider>
          </IntlProvider>
        </StoreProvider>
      </GraphqlProvider>
    );
  }
  unregister(); // TODO: register service worker later
}
