import { Notifier } from 'airbrake-browser-esm';
import version from './version';

let airbrakeClient;

airbrakeClient = new Notifier({
  projectId: 142847,
  projectKey: 'e01de8df2fe5d905a02f767a193fc2cc',
});

let environment = 'development';
if (window.location.hostname === 'my.drinkripples.com') {
  environment = 'production';
} else if (window.location.hostname === 'my-st.drinkripples.com') {
  environment = 'staging';
}

airbrakeClient.addFilter(function(notice) {
  notice.context.environment = environment;
  notice.context.version = version;
  return notice;
});

export function notify(err) {
  if (!airbrakeClient || environment === 'development') {
    console.error(err);
  } else {
    airbrakeClient.notify(err);
  }
}
