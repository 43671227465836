import { createSlice } from '@reduxjs/toolkit';
import { getAuthHeaders } from '../../../../Auth';
import { t } from '../../../../base/Translate';
import axiosClient from '../../../../helpers/axiosClient';

const versionsSlice = createSlice({
  name: 'versions',
  initialState: {
    loading: false,
    error: undefined,
    appsVersions: undefined,
    rm2Versions: undefined,
    embeddedVersions: undefined,
    defaultAppsVersion: undefined,
    defaultRm2Version: undefined,
  },
  reducers: {
    versionsLoading(state) {
      state.loading = true;
    },
    versionsReceived(state, action) {
      const {
        appsVersions,
        rm2Versions,
        embeddedVersions,
        defaultAppsVersion,
        defaultRm2Version,
      } = action.payload;

      state.loading = false;
      state.error = undefined;
      state.appsVersions = appsVersions;
      state.rm2Versions = rm2Versions;
      state.embeddedVersions = embeddedVersions;
      state.defaultAppsVersion = defaultAppsVersion;
      state.defaultRm2Version = defaultRm2Version;
    },
    versionsFetchFailed(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    addEmbeddedVersion(state, action) {
      state.embeddedVersions.push(action.payload);
    },
    addAppVersion(state, action) {
      state.appsVersions.push(action.payload);
    },
    updateRm2Version(state, action) {
      updateVersion(state.rm2Versions, action);
    },
    updateAppsVersion(state, action) {
      updateVersion(state.appsVersions, action);
    },
    updateEmbeddedVersion(state, action) {
      updateVersion(state.embeddedVersions, action);
    },
    setDefaultAppsVersion(state, action) {
      state.defaultAppsVersion = action.payload;
    },
    setDefaultRm2Version(state, action) {
      state.defaultRm2Version = action.payload;
    },
  },
});

const updateVersion = (state, action) => {
  const {
    productionReady,
    description,
    version: versionToFind,
  } = action.payload;
  const version = state.find((ver) => ver.version === versionToFind);

  if (productionReady !== undefined) {
    version.productionReady = productionReady;
  }

  if (description !== undefined) {
    version.description = description;
  }
};

export const fetchVersions = () => async (dispatch) => {
  dispatch(versionsLoading());
  try {
    const res = await axiosClient.get('/api/makers/versions', getAuthHeaders());
    const result = res.data.data;
    dispatch(versionsReceived(result));
  } catch (err) {
    dispatch(versionsFetchFailed(err.message));
  }
};

export const getFactoryDefaultEmbeddedVersion = ({
  defaultAppsVersion,
  versionsLoading,
  appsVersions,
}) => {
  const version =
    appsVersions &&
    appsVersions.find(
      (appsVersion) => appsVersion.version === defaultAppsVersion
    );

  if (versionsLoading || !version || !version.matchingVersion) {
    return t('Default');
  }

  return `${version.matchingVersion} (${t('Factory Version')})`;
};

export const {
  versionsLoading,
  versionsReceived,
  versionsFetchFailed,
  addAppVersion,
  addEmbeddedVersion,
  setDefaultAppsVersion,
  setDefaultRm2Version,
  updateAppsVersion,
  updateRm2Version,
  updateEmbeddedVersion,
} = versionsSlice.actions;
export const { reducer: versionsReducer } = versionsSlice;
export default versionsReducer;
