// @flow
import { match } from 'path-to-regexp';

const PARAMS_PATTERN = /\{([0-9a-zA-Z_]+)}/g;
// TODO 21 May 2018 - deprecated, remove this.
const SCOPE_PATTERN = /^\/(ripples|(org|hub|partner|care-center)\/([^\/]+))\//; // eslint-disable-line no-useless-escape
const URL_MATCH_REPLACE_PATTERN = /({([^\/]+)})/g; // eslint-disable-line no-useless-escape

type Params = { [key: string]: string };

export class Route {
  +_scope: string;
  +_subScope: string;
  +_path: string;
  +_url: string;
  +_url_pattern: string;
  +_id: string;
  +_breadcrumbs: Array<string>;

  constructor(
    scope: string,
    subScope?: string = '',
    path?: string = '',
    id: string = '',
    breadcrumbs?: Array<string>
  ) {
    this._scope = scope;
    this._subScope = subScope;
    this._path = path;

    let url = `/${scope}`;
    if (!!subScope) {
      url += `/${subScope}`;
    }
    if (!!path) {
      url += `/${path}`;
    }
    this._url = url;
    this._url_pattern = url.replace(URL_MATCH_REPLACE_PATTERN, function(
      match,
      p1,
      p2
    ) {
      return `:${p2}`;
    });

    this._id = id;
    this._breadcrumbs = breadcrumbs || [];
  }

  url(params: Params = {}): string {
    return this.replace(this._url, params);
  }

  // TODO 21 May 2018 - deprecated, remove this.
  scopeUrl(params: Params = {}): string {
    let url = this.url(params);
    return url.replace(SCOPE_PATTERN, '');
  }

  scope(params: Params = {}): string {
    return this.replace(this._scope, params);
  }

  subScope(params: Params = {}): ?string {
    if (!!this._subScope) {
      return this.replace(this._subScope, params);
    }

    return this._subScope;
  }

  path(params: Params = {}): ?string {
    if (!!this._path) {
      return this.replace(this._path, params);
    }
    return this._path;
  }

  id(): ?string {
    return this._id;
  }

  replace(part: string, params: Params = {}): string {
    return part.replace(
      PARAMS_PATTERN,
      (match, i, index) => params[i] || '$notset$'
    );
  }

  breadcrumbs() {
    return this._breadcrumbs;
  }

  matches(path: string): boolean {
    const matcher = match(this._url_pattern, { decode: decodeURIComponent });
    const result = matcher(path);
    return !!result;
  }
}

export type Page = Route;
export type Category = $ReadOnly<{ [key: string]: Route }>;
