// @flow
import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import LoaderPage from '../lib/layout/LoaderPage';
import withNavigate from '../hoc/withNavigate';
import { getScopeRoot } from '../locations';
import NoScopePage from './NoScopePage';

type Props = {
  defaultScope: string | 'loading' | 'empty',
  navigate: (url: string) => void,
};

const ROOT_REDIRECT_QUERY = gql`
  query {
    viewer {
      me {
        id
        defaultScope
      }
    }
  }
`;

const RootRedirectPage = ({ navigate }: Props) => {
  const { data, loading } = useQuery(ROOT_REDIRECT_QUERY);

  const defaultScope: string | 'loading' | 'empty' = loading
    ? 'loading'
    : data?.viewer?.me?.defaultScope || 'empty';

  useEffect(
    () => {
      if (defaultScope !== 'loading' && defaultScope !== 'empty') {
        const parts = defaultScope.split(':');
        const root = getScopeRoot(parts[1]);
        navigate(root.url());
      }
    },
    [defaultScope, navigate]
  );

  return defaultScope === 'empty' ? <NoScopePage /> : <LoaderPage />;
};

export default withNavigate()(RootRedirectPage);
