// @flow strict
import * as React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
  from as fromLink,
} from '@apollo/client';

import { onError } from '@apollo/client/link/error';
import { auth } from './Auth';
import * as operations from './operations';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        viewer: {
          merge: true,
          // merge(existing, incoming, { mergeObjects }) {
          //   return mergeObjects(existing, incoming);
          // },
        },
      },
    },
  },
});

const baseURL = process.env.REACT_APP_BASE_API_URL;
console.log(`Using Base API Url: ${baseURL}`);
if(!baseURL){
  throw Error('** Please set REACT_APP_BASE_API_URL **')
}
const httpLink = new HttpLink({ uri: `${baseURL}/graphql`, credentials: 'same-origin' });

const callMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${auth.getAccessToken()}`,
    },
  }));

  return forward(operation).map((response) => {
    switch (response.statusCode) {
      case 200: {
        let errors = response.data.errors || [];
        for (let err of errors) {
          if (err.type === 'MissingPermission') {
            console.error(
              `Missing permission on object ${err.object}#${err.property
              } at ${err.path.join('.')} : ${err.permission}`
            );
          }
        }
        break;
      }

      case 401: {
        console.info('Not authorized! logging out..');
        operations.logout();
        break;
      }

      default:
        break;
    }

    return response;
  });
});

const unauthErrorAfterware = onError(({ networkError }) => {
  if (!!networkError && networkError.statusCode === 401) {
    console.info('Not authorized! logging out..');
    operations.logout();
  }
});

const client = new ApolloClient({
  link: fromLink([callMiddleware, unauthErrorAfterware, httpLink]),
  cache,
});

const GraphqlProvider = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

const clearCache = async (): Promise<any> => {
  await client.clearStore();
  return true;
};

export { GraphqlProvider, clearCache };
