import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CoffeeIcon from './img/CoffeeIcon';
import BeerIcon from './img/BeerIcon';

export default class AppVariantIcon extends PureComponent {
  static propTypes = {
    appVariant: PropTypes.string,
    size: PropTypes.number,
    fill: PropTypes.string,
  };

  static defaultProps = {
    size: 15,
    fill: '#333333',
  };

  resolveIcon = (appVariant, size, fill) => {
    if (
      appVariant &&
      appVariant
        .trim()
        .toLowerCase()
        .indexOf('beer') === 0
    ) {
      return <BeerIcon size={size} fill={fill} />;
    }

    return <CoffeeIcon size={size} fill={fill} />;
  };

  render() {
    const { appVariant, size, fill } = this.props;
    return this.resolveIcon(appVariant, size, fill);
  }
}
