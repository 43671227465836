import React from 'react';
import './Logo.css';
import classnames from 'classnames';

export default function Logo({ white, size }) {
  size = size || 28;
  return (
    <svg
      className={classnames('ripples-logo', { white: !!white })}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={size}
      height={size}
      viewBox="0 0 48 48"
    >
      <circle r="24" cy="24" cx="24" className="round" />
      <path
        d="M29.27,7.8H20.38a12.5,12.5,0,0,0-1.75.13A12.74,12.74,0,0,0,7.91,18c0,.18-.07.36-.1.56a12.5,12.5,0,0,0-.16,2v8.89a12.73,12.73,0,0,0,25,3.38,12.73,12.73,0,0,0-3.38-25Zm1.51,23.14a10.41,10.41,0,0,1-.88,2.93,10.51,10.51,0,0,1-20-4.44V20.53A10.34,10.34,0,0,1,10,18.86c0-.16,0-.3.08-.45a10.56,10.56,0,0,1,8.86-8.3H19a10.22,10.22,0,0,1,1.37-.1h8.89a10.51,10.51,0,0,1,1.51,20.92Z"
        className="lines"
      />
      <path
        d="M29.27,12.23H20.38a8,8,0,0,0-1.14.08,8.3,8.3,0,0,0-7,6.55c0,.11,0,.22-.06.34a8.31,8.31,0,0,0-.11,1.33v8.89a8.3,8.3,0,1,0,16.6,0v-.59h.59a8.3,8.3,0,1,0,0-16.6ZM30.5,26.49a6.12,6.12,0,0,1-1.22.12H26.47v2.81a6.08,6.08,0,0,1-2.63,5l-.06,0-.23.14-.27.16-.15.07-.39.19-.38.14-.18.06-.18,0a6.07,6.07,0,0,1-7.71-5.85V20.53a5.84,5.84,0,0,1,.09-1c0-.08,0-.16,0-.23a6.08,6.08,0,0,1,5.12-4.8,5.82,5.82,0,0,1,.84-.06h8.89a6.08,6.08,0,0,1,1.22,12Z"
        className="lines"
      />
    </svg>
  );
}
