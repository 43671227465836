import React, { PureComponent } from 'react';

import { t } from '../../base/Translate';
import { Record, List } from 'immutable';
import { BreadcrumbsConsumer } from '../BreadcrumbsProvider';

import { Button } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LoadingOverlay from '../LoadingOverlay';
import { Tooltip } from 'antd';
import WarningBanner from '../common/WarningBanner';
import ActionsBar, { Action, SearchInput } from './ActionBar';
import './Page.css';
import { InfoCircleOutlined } from '@ant-design/icons';

export const PageSideAction = Record({
  key: '',
  label: '',
  icon: null,
  disabled: true,
  selected: false,
  isVisible: false,
  onClick: () => {},
});

/**
 * Control used by all pages as a wrapper of common functionality
 */
export default class Page extends PureComponent {
  static propTypes = {
    fab: PropTypes.node,
    modals: PropTypes.node,
    title: PropTypes.string,
    children: PropTypes.node,
    onSearch: PropTypes.func,
    withStripe: PropTypes.bool,
    showWarning: PropTypes.bool,
    breadcrumbs: PropTypes.bool,
    onPageClick: PropTypes.func,
    isSearchable: PropTypes.bool,
    onSearchEnter: PropTypes.func,
    warningText: PropTypes.string,
    onSearchClear: PropTypes.func,
    searchComponent: PropTypes.node,
    disableScrolling: PropTypes.bool,
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    actions: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(List)]),
    TitlePlugin: PropTypes.any,
  };

  static defaultProps = {
    title: '',
    loading: false,
    warningText: '',
    breadcrumbs: true,
    withStripe: false,
    onSearch: () => {},
    showWarning: false,
    isSearchable: false,
    warningBanner: null,
    onPageClick: () => {},
    onSearchClear: () => {},
    disableScrolling: false,
    onSearchEnterPressed: () => {},
  };

  renderSearchInput = () => {
    const {
      title,
      count,
      onSearch,
      totalCount,
      defaultValue,
      onSearchClear,
      searchDisabled,
      renderNextAndPrev,
      handleNextClicked,
      handlePrevClicked,
      onSearchEnterPressed,
      hasSelectOption,
      selectedField,
      setField,
      availableFields,
      selectClassName,
    } = this.props;

    const placeholder = title
      ? `${t(['c7c821c3-e3c4-4804-83e6-a46d0bb9daa4', 'Search in:'])} ${t(
          title
        )}`
      : null;
    return (
      <SearchInput
        count={count}
        onSearch={onSearch}
        totalCount={totalCount}
        placeholder={placeholder}
        disabled={searchDisabled}
        defaultValue={defaultValue}
        onSearchClear={onSearchClear}
        renderNextAndPrev={renderNextAndPrev}
        handleNextClicked={handleNextClicked}
        handlePrevClicked={handlePrevClicked}
        onSearchEnterPressed={onSearchEnterPressed}
        hasSelectOption={hasSelectOption}
        selectedField={selectedField}
        setField={setField}
        availableFields={availableFields}
        selectClassName={selectClassName}
      />
    );
  };

  displayWarningBanner = () => {
    const { warningBanner } = this.props;
    if (!warningBanner || (!warningBanner.text && !warningBanner.defaultText)) {
      return <React.Fragment />;
    }

    return (
      <WarningBanner
        text={warningBanner.text}
        defaultText={warningBanner.defaultText}
      />
    );
  };

  render() {
    let actionsWithBar = this.props.actions;

    const {
      title,
      loading,
      withStripe,
      onPageClick,
      showWarning,
      warningText,
      isSearchable,
      TitlePlugin,
    } = this.props;

    if (actionsWithBar instanceof List) {
      actionsWithBar = (
        <ActionsBar
          title={title}
          label="Actions"
          showWarning={showWarning}
          warningText={warningText}
          search={this.props.searchComponent}
        >
          {!!isSearchable &&
            !this.props.searchComponent &&
            this.renderSearchInput()}
          {actionsWithBar.filter((action) => action.isVisible).map((action) => (
            <Action
              key={action.label}
              label={action.label}
              disabled={action.disabled}
              selected={action.selected}
              onClick={() => {
                action.onClick(action.key, action.label);
              }}
            >
              {action.icon}
            </Action>
          ))}
        </ActionsBar>
      );
    } else if (this.props.actions) {
      actionsWithBar = (
        <div className="rpl-ActionBar">
          <div className="rpl-ActionBar__inner">{this.props.actions}</div>
        </div>
      );
    } else if (isSearchable) {
      actionsWithBar = <ActionsBar>{this.renderSearchInput()};</ActionsBar>;
    } else if (actionsWithBar && actionsWithBar.type !== ActionsBar) {
      actionsWithBar = null;
    }

    let className = this.props.className || [];
    if ('string' === typeof className) {
      className = [className];
    }
    className = ['rpl-Page__wrapper'].concat(className);
    const showBreadcrumbs = this.props.breadcrumbs;

    return (
      <BreadcrumbsConsumer>
        {(breadcrumbs) => (
          <LoadingOverlay loading={loading}>
            <div className={classnames.apply(null, className)}>
              <div style={{ zIndex: 10 }}>{this.props.modals}</div>
              {actionsWithBar}
              <div
                className={classnames('rpl-Page__content-container', {
                  'rpl-Page__content-container__fixed': !!actionsWithBar,
                  'rpl-Page__content-container-no-scrolling': this.props
                    .disableScrolling,
                })}
                onClick={onPageClick}
              >
                {withStripe && <Stripe icon="link" />}
                {this.displayWarningBanner()}
                <div className="rpl-Page__content">
                  {!breadcrumbs &&
                    title && <div className="rpl-Page__title">{title}</div>}
                  {!!breadcrumbs &&
                    showBreadcrumbs && (
                      <div className="rpl-Page__breadcrumbs-container">
                        <div className="rpl-Page__breadcrumbs">
                          {breadcrumbs}
                          {TitlePlugin && <TitlePlugin />}
                        </div>
                      </div>
                    )}
                  {this.props.children}
                </div>
              </div>
              {this.props.fab}
            </div>
          </LoadingOverlay>
        )}
      </BreadcrumbsConsumer>
    );
  }
}

export { List };

function MainAction({ onClick, isDisabled = false, children }) {
  return (
    <div className="rpl-MainAction">
      <div
        className={`rpl-MainAction-Button_${isDisabled ? 'disabled' : ''}`}
        onClick={onClick}
        disabled={isDisabled}
      >
        {children}
      </div>
    </div>
  );
}

MainAction.propTypes = {
  isDisabled: PropTypes.bool,
  children: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

function FooterActions({
  error,
  className,
  onPrimaryAction,
  isPrimaryHidden,
  isPrimaryLoading = false,
  onSecondaryAction = null,
  isPrimaryDisabled = false,
  primaryToolTipText,
  primaryButtonLabel = 'Save',
  secondaryButtonLabel = 'Cancel',
}) {
  const primaryButton = (
    <Button
      type="primary"
      className={classnames('rpl-FooterActions__drawer-button primary', {
        disabled: isPrimaryDisabled,
      })}
      onClick={onPrimaryAction}
      loading={isPrimaryLoading}
      disabled={isPrimaryDisabled}
    >
      {primaryButtonLabel}
    </Button>
  );

  return (
    <div className={classnames('rpl-FooterActions', className)}>
      {onSecondaryAction && (
        <Button
          className="rpl-FooterActions__drawer-button"
          onClick={onSecondaryAction}
        >
          {secondaryButtonLabel}
        </Button>
      )}
      {error && (
        <span className="rpl-FooterActions__error">
          <InfoCircleOutlined title={error} />&nbsp;
          {error}
        </span>
      )}
      {!isPrimaryHidden && (
        <React.Fragment>
          {primaryToolTipText ? (
            <Tooltip title={primaryToolTipText} plcement="top">
              {primaryButton}
            </Tooltip>
          ) : (
            <React.Fragment>{primaryButton}</React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

FooterActions.propTypes = {
  isPrimaryHidden: PropTypes.bool,
  isPrimaryLoading: PropTypes.bool,
  onSecondaryAction: PropTypes.func,
  isPrimaryDisabled: PropTypes.bool,
  primaryTooltipText: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  onPrimaryAction: PropTypes.func.isRequired,
};

export { MainAction, FooterActions };

type Props = {
  icon?: 'link',
};

class Stripe extends React.PureComponent<Props> {
  static defaultProps = {
    icon: null,
  };

  render() {
    return (
      <div className="rpl-Page__stripe-mark">
        {this.props.icon === 'link' && (
          <div className="rpl-Page__stripe-mark-glyph-link" />
        )}
      </div>
    );
  }
}
