// @flow
import * as React from 'react';
import Page from '../lib/layout/Page';
import Spinner from '../lib/Spinner';
import { branch, renderComponent } from 'recompose';

export default function withLoader(
  isLoading: (any) => boolean = ({ loading }) => !!loading
) {
  return branch(isLoading, renderComponent(LoadingPage));
}

function LoadingPage() {
  return (
    <Page title="Loading">
      <Spinner />
    </Page>
  );
}
