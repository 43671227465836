import React from 'react';
import PropTypes from 'prop-types';
import withNavigate from '../hoc/withNavigate';
import { SidebarCategory, SidebarItem } from '../lib/layout/Sidebar';
import filter from 'lodash/fp/filter';
import { extractPartsFromPath } from '../locations';
import { t } from '../base/Translate';
import './category.css';

const Category = ({ title, isNew, items, onItemClicked, location }) => {
  const currentPath = location && location.pathname ? location.pathname : '';
  const currentPathParts = extractPartsFromPath(currentPath);

  // filter to only the items the user can use
  let itemsElements = filter((i) => !!i)(
    items.map((item, i) => {
      const itemParts = item.to ? extractPartsFromPath(item.to) : null;
      const isHighlighted =
        !!currentPathParts &&
        !!itemParts &&
        currentPathParts.subScope === itemParts.subScope;

      return !item.visible ? null : (
        <SidebarItem
          onItemClicked={() => {
            onItemClicked(item);
          }}
          key={i}
          highlighted={isHighlighted}
          to={item.to}
        >
          {item.label}

          {item.isNew && (
            <h6 className={'RPL-Category_subcategory_newImg'}>
              {t(['997f768d-c8d9-483f-8dc4-3cd55aef2b33', 'New'])}
            </h6>
          )}
        </SidebarItem>
      );
    })
  );
  return itemsElements.length ? (
    <div>
      <div style={{ display: 'flex' }}>
        <SidebarCategory>{title}</SidebarCategory>
        {isNew && (
          <h6 className={'RPL-Category_subcategory_newImg'}>
            {t(['997f768d-c8d9-483f-8dc4-3cd55aef2b33', 'New'])}
          </h6>
        )}
      </div>
      {itemsElements}
    </div>
  ) : null;
};

Category.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  isNew: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      to: PropTypes.string,
      link: PropTypes.string,
      visible: PropTypes.bool,
      isNew: PropTypes.bool,
    })
  ).isRequired,
  onItemClicked: PropTypes.func.isRequired,
};

export default withNavigate()(Category);
