// @flow
import * as React from 'react';
import classnames from 'classnames';
import { BreadcrumbLabel, getLabelForPath } from '../gen/BreadcrumbLabel.gen';
import './BreadcrumbItem.css';

type Props = {
  id: string,
  url: string,
  isCurrent: boolean,
  onClick?: (url: string) => void,
};

type State = {};

export class BreadcrumbItem extends React.PureComponent<Props, State> {
  static defaultProps = {
    id: null,
    url: null,
    isCurrent: false,
    onClick: null,
  };

  handleClick = () => {
    const { onClick, url } = this.props;
    if (!!onClick) {
      onClick(url);
    }
  };

  render() {
    const { id, isCurrent } = this.props;
    return (
      <div className="rpl-BreadcrumbItem">
        <div
          className={classnames(
            'rpl-BreadcrumbItem__container',
            'enabled',
            isCurrent ? 'current' : ''
          )}
          onClick={this.handleClick}
        >
          <BreadcrumbLabel id={id} />
        </div>
      </div>
    );
  }
}

export const BreadcrumbItemSeparator = () => {
  return (
    <div className="rpl-BreadcrumbItem">
      <div className={classnames('rpl-BreadcrumbItem__container', 'disabled')}>
        <span>/</span>
      </div>
    </div>
  );
};

export const canShowBreadcrumb = (pathId: string): boolean => {
  return !!getLabelForPath(pathId);
};
