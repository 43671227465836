// @flow strict
import * as network from '../network';
import { auth } from '../Auth';

const TAG = 'operations/logout';

const logout = async (): Promise<any> => {
  if (auth.isAuthenticated()) {
    try {
      await auth.clearRolesCache();
    } catch (e1) {
      console.warn(TAG, 'failed clearing roles cache', e1);
    }
  }

  await network.clearCache();
  await auth.logout();

  return true;
};

export default logout;
