import { createSlice } from '@reduxjs/toolkit';

const contentSlice = createSlice({
  name: 'content',
  initialState: {
    contentLocation: '',
    contentOrganization: '',
  },
  reducers: {
    setContentLocation(state, action) {
      state.contentLocation = action.payload;
    },
    setContentOrganization(state, action) {
      state.contentOrganization = action.payload;
    },
    resetContentState(state) {
      state.contentLocation = '';
      state.contentOrganization = '';
    },
  },
});

export const {
  resetContentState,
  setContentLocation,
  setContentOrganization,
} = contentSlice.actions;
export const { reducer: contentReducer } = contentSlice;
export default contentReducer;
