import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import fetch from 'isomorphic-fetch';
import { compose, withHandlers } from 'recompose';
import { t } from '../base/Translate';

export const CREATE_UPLOAD_URL = gql`
  mutation createUploadUrl(
    $removeOpacity: Boolean!
    $isMulticolor: Boolean!
    $organization: String!
  ) {
    createUploadUrl(
      input: {
        clientMutationId: "1"
        removeOpacity: $removeOpacity
        isMulticolor: $isMulticolor
        organization: $organization
      }
    ) {
      clientMutationId
      url
      params
    }
  }
`;

const CREATE_RIPPLE = gql`
  mutation createRipple(
    $url: String!
    $fingerprint: String!
    $organization: String!
    $languageCode: String!
    $dayOfTheWeek: [String]!
    $timeOfTheDay: [String]!
    $isTemplate: Boolean!
    $isShareable: Boolean!
    $isFrame: Boolean!
    $isMacaroonFrame: Boolean!
    $isPremium: Boolean!
    $isMulticolor: Boolean!
    $drink: String
    $blend: String
    $tags: [String]
    $canApproveRipple: Boolean!
  ) {
    createRipple(
      input: {
        clientMutationId: "1"
        url: $url
        fingerprint: $fingerprint
        organization: $organization
        languageCode: $languageCode
        dayOfTheWeek: $dayOfTheWeek
        timeOfTheDay: $timeOfTheDay
        isTemplate: $isTemplate
        isShareable: $isShareable
        isFrame: $isFrame
        isMacaroonFrame: $isMacaroonFrame
        drink: $drink
        blend: $blend
        isPremium: $isPremium
        isMulticolor: $isMulticolor
        tags: $tags
        canApproveRipple: $canApproveRipple
      }
    ) {
      clientMutationId
      ripple {
        id
        rippleImage {
          thumbnailUrl
        }
      }
    }
  }
`;

export default function withUploadRipplesAction(refetchQueriesWhenFinish = []) {
  return compose(
    graphql(CREATE_UPLOAD_URL, {
      props: ({ mutate, ownProps: { uploads, setUploads } }) => ({
        createUploadUrl: ({ removeOpacity, isMulticolor, organization }) => {
          return mutate({
            variables: {
              removeOpacity,
              isMulticolor,
              organization,
            },
          })
            .then((res) => {
              if (res.error) {
                throw new Error('Failed to get upload URL');
              }
              return res.data.createUploadUrl;
            })
            .catch((error) => {
              if (error.graphQLErrors) {
                const gQlerror = error.graphQLErrors[0];
                throw new Error(t(gQlerror.message));
              }
            });
        },
      }),
    }),
    graphql(CREATE_RIPPLE, {
      props: ({ mutate }) => ({
        createRipple: ({
          url,
          fingerprint,
          organization,
          languageCode,
          dayOfTheWeek,
          timeOfTheDay,
          isTemplate,
          isShareable,
          isFrame,
          isMacaroonFrame,
          drink,
          blend,
          isPremium,
          isMulticolor,
          isApproved,
          tags,
          canApproveRipple,
        }) =>
          mutate({
            variables: {
              url,
              fingerprint,
              organization,
              languageCode,
              dayOfTheWeek,
              timeOfTheDay,
              isTemplate,
              isShareable,
              isFrame,
              isMacaroonFrame,
              drink,
              blend,
              isPremium,
              isMulticolor,
              isApproved,
              tags,
              canApproveRipple,
            },
          }),
      }),
      options: (props) => ({
        refetchQueriesWhenFinish,
      }),
    }),
    withHandlers({
      uploadRipples: ({ createUploadUrl, createRipple }) => async (
        files,
        metadata,
        organization,
        canApproveRipple,
        progressCallback
      ) => {
        const {
          languageCode,
          dayOfTheWeek,
          timeOfTheDay,
          isPersonalized: isTemplate,
          isShareable,
          isFrame,
          isMacaroonFrame,
          drink,
          blend,
          isPremium,
          isMulticolor,
          tags,
        } = metadata;

        try {
          const result = await createUploadUrl({
            removeOpacity: !isFrame,
            isMulticolor: isMulticolor,
            organization,
          });
          let { url, params } = result;

          let uploaded = await uploadFiles(
            files,
            url,
            JSON.parse(params),
            progressCallback
          );

          return await Promise.all(
            uploaded.map(({ url, phash: fingerprint }) => {
              return createRipple({
                url,
                fingerprint,
                organization,
                languageCode,
                dayOfTheWeek,
                timeOfTheDay,
                isTemplate,
                isShareable,
                isFrame,
                isMacaroonFrame,
                drink,
                blend,
                isPremium,
                isMulticolor,
                tags,
                canApproveRipple,
              }).then(({ data }) => {
                const ripple = data.createRipple.ripple;

                return {
                  id: ripple.id,
                  image: ripple.rippleImage.thumbnailUrl,
                };
              });
            })
          );
        } catch (error) {
          throw new Error(error.message);
        }
      },
    })
  );
}

export const uploadFiles = async (files, url, params, progressCallback) => {
  let bodies = [];
  let totalSize = 0;
  let uploaded = 0;

  const isDataUrlFile = typeof files === 'string';

  if (isDataUrlFile) {
    let formData = new FormData();
    for (let key in params) {
      formData.set(key, params[key]);
    }
    let blob = await fetch(files).then((res) => res.blob());
    formData.set('file', blob);
    bodies.push(formData);

    totalSize = 1;
  } else {
    Array.from(files).forEach((file) => {
      let formData = new FormData();
      for (let key in params) {
        formData.set(key, params[key]);
      }
      formData.set('file', file.fileReader.result);
      bodies.push(formData);

      totalSize += file.fileReader.result.length;
    });
  }

  return Promise.all(
    bodies.map((body) => {
      return fetch(url, { method: 'POST', body })
        .then((res) => res.json())
        .then((json) => {
          if (!isDataUrlFile) {
            uploaded += body.get('file').length;

            if (!!progressCallback) {
              progressCallback((uploaded / totalSize) * 100);
            }
          } else {
            if (!!progressCallback) {
              progressCallback(1);
            }
          }
          return {
            url: json.secure_url,
            phash: json.phash,
          };
        });
    })
  ).then((res) => res);
};
