import { createContext, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';

export default function withHistoryListener() {
  return function(Component) {
    return function(props) {
      const history = createBrowserHistory();

      const listenToHistory = (fn) => history.listen(fn);

      return (
        <Component {...props} listenToHistory={listenToHistory}>
          {props.children}
        </Component>
      );
    };
  };
}
