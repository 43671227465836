// Design Token

const colorPrimary = '#e86161';

const theme = {
  token: {
    colorPrimary,
  },
  components: {
    Table: {
      rowSelectedBg: 'transparent',
      rowSelectedHoverBg: 'transparent',
    },
    Pagination: {
      borderRadius: 4,
      colorBgTextHover: '#fff',
      colorBgTextActive: '#fff',
    },
    Button: {
      borderRadius: 0,
      colorLinkHover: colorPrimary,
      colorLinkActive: colorPrimary,

      defaultActiveBorderColor: colorPrimary,
      defaultActiveColor: colorPrimary,

      defaultHoverBorderColor: colorPrimary,
      defaultHoverColor: colorPrimary,

      groupBorderColor: colorPrimary,
      colorPrimaryHover: colorPrimary,
    },
    Radio: {
      colorPrimary: '#000',
      dotSize: 8,
    },
    Checkbox: {
      borderRadiusSM: 0,
    },
    Spin: {
      contentHeight: '100%',
      colorBgContainer: 'transparent',
    },
    Tabs: {
      inkBarColor: '#000',
      itemHoverColor: '#000',
      itemSelectedColor: '#000',
      horizontalItemPadding: '12px 65px',
      colorBorderSecondary: 'transparent',
    },
    Input: {
      borderRadius: 0,
    },
    DatePicker: {
      cellActiveWithRangeBg: 'rgba(232, 97, 97, .2)',
    },
  },
};

export default theme;
