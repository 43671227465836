// @flow
import { Route, type Page } from './locations_types';
import { OrganizationScope } from './gen/locations.gen';

// TODO 21 May 2018 - deprecated, remove this.
const SCOPE_PATTERN = /^\/(ripples|(org|hub|partner|care-center)\/([^\/]+))\//; // eslint-disable-line no-useless-escape
const PART_PATTERN = /^\/(ripples|(org|hub|partner|care-center)\/([^\/]+))(\/([^\/]+)(\/(.*))?)?(?:\/)?$/; // eslint-disable-line no-useless-escape

// TODO 21 May 2018 - deprecated, remove this.
export function extractScopeFromPath(
  path: string
): { type: string, id?: string } | null {
  let match: ?Array<string> = SCOPE_PATTERN.exec(path);
  if (match) {
    if (match.length === 2) {
      return { type: match[1] };
    } else if (match.length === 4) {
      return { type: match[2], id: match[3] };
    }
  }
  return null;
}

export function extractPartsFromPath(
  path: string
): {
  scope: string,
  scopeId?: string,
  subScope?: string,
  path?: string,
} | null {
  let matches = PART_PATTERN.exec(path);
  if (!!matches && matches.length === 8) {
    return {
      scope: matches[1],
      scopeId: matches[3],
      subScope: matches[5],
      path: matches[7],
    };
  }

  return null;
}

export class RipplesNavigator {
  org: (org: string) => OrganizationScope;

  constructor() {
    this.org = (org) => new OrganizationScope(org);
  }
}
const ripplesNavigator = new RipplesNavigator();

export function buildBreadcrumbs(
  paths: Array<string>,
  scopeId?: string,
  params: {} = {}
): Array<{ id: string, url: string }> | [] {
  return paths
    .map((path) => {
      let found: Route | null = null;

      found = findRoute(path, ripplesNavigator.ripples);
      if (!!scopeId) {
        // found = found || findRoute(path, ripplesNavigator.partner(scopeId));
        found = found || findRoute(path, ripplesNavigator.org(scopeId));
      }

      if (found) {
        return {
          id: found.id(),
          url: found.url(params),
        };
      }

      return null;
    })
    .filter((breadcrumb) => !!breadcrumb);
}

export function findRouteFromPath(
  scopeId?: string,
  path: string
): { route: Route, params: {} } | null {
  let found: Route | null = null;

  found = findMatchingRoute(path, ripplesNavigator.ripples);
  if (!!scopeId) {
    found = found || findMatchingRoute(path, ripplesNavigator.org(scopeId));
  }

  return found;
}

function findRoute(path: string, object: any) {
  if (object instanceof Route) {
    if (path === object.id()) {
      return object;
    }
  }

  if (typeof object !== 'object') {
    return null;
  }

  for (let prop in object) {
    let found = findRoute(path, object[prop]);
    if (!!found) {
      return found;
    }
  }

  return null;
}

function findMatchingRoute(path: string, object: any) {
  if (object instanceof Route) {
    if (object.matches(path)) {
      return object;
    }
  }

  if (typeof object !== 'object') {
    return null;
  }

  for (let prop in object) {
    let found = findMatchingRoute(path, object[prop]);
    if (!!found) {
      return found;
    }
  }

  return null;
}

export default ripplesNavigator;

export function getScopeRoot(scopeId: string): Page {
  return ripplesNavigator.org(scopeId).index;
}
