import { createSlice } from '@reduxjs/toolkit';
import { getAuthHeaders } from '../../../../Auth';
import axiosClient from '../../../../helpers/axiosClient';

// TODO: make sure the store is updated when a organization or an organization is created
const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: {
    allOrganizationsLoading: false,
    allOrganizations: undefined,
    allOrganizationsWithLocations: undefined, // allOrganizations include orgs without locations, allOrganizationsWithLocations include only orgs with location
    allOrganizationsError: false,
    allOrganizationsErrorMessage: undefined,
    searchTerm: undefined,
    sort: undefined,
    loading: false,
    totalCount: undefined,
    pageNumber: 1,
    pageSize: 10,
    error: false,
    errorMessage: undefined,
    organizations: undefined,
    currentOrganizationName: undefined,
    currentOrganizationId: undefined,
    activeServiceLevel: undefined,
    scopes: 'loading',
  },
  reducers: {
    allOrganizationsLoading(state) {
      state.allOrganizationsLoading = true;
    },
    allOrganizationsError(state, action) {
      state.allOrganizationsError = true;
      state.errorMessage = action.payload;
    },
    allOrganizationsReceived(state, action) {
      state.allOrganizationsLoading = false;
      state.allOrganizations = action.payload;
      const allOrganizationsWithLocations = action.payload.filter(
        (org) => Array.isArray(org.locations) && org.locations.length
      );
      state.allOrganizationsWithLocations = allOrganizationsWithLocations;
    },
    organizationsLoading(state) {
      state.loading = true;
    },
    organizationsError(state, action) {
      state.error = true;
      state.errorMessage = action.payload;
    },
    organizationsReceived(state, action) {
      const {
        page,
        pageNumber,
        pageSize,
        totalCount,
        sort,
        searchTerm,
      } = action.payload;

      state.loading = false;
      state.organizations = page;
      state.pageNumber = pageNumber;
      state.pageSize = pageSize;
      state.searchTerm = searchTerm;
      state.totalCount = totalCount;
      state.searchTerm = searchTerm;
      state.sort = sort;
    },
    organizationChanged(state, action) {
      const { orgId, name, googleAnalyticsId, features } = action.payload;
      const organization =
        state.organizations &&
        state.organizations.find((org) => org.id === orgId);

      if (organization) {
        organization.name = name;
        organization.googleAnalyticsId = googleAnalyticsId;
        organization.features = features;
      }
    },
    resetOrganizations(state, action) {
      state.allOrganizations = undefined;
      state.allOrganizationsWithLocations = undefined;
    },
    setActiveServiceLevel(state, action) {
      state.activeServiceLevel = action.payload.activeServiceLevel;
    },
    setCurrentOrganization(state, action) {
      const { name, id } = action.payload;
      state.currentOrganizationId = id;
      state.currentOrganizationName = name;
      state.searchTerm = undefined;
    },
    setCurrentScopes(state, action) {
      state.scopes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('locations/locationEdited', (state, action) => {
        const { name, id } = action.payload;

        if (state.organizations) {
          for (const organization of state.organizations) {
            const location =
              organization &&
              organization.locations &&
              organization.locations.find((location) => location._id === id);
            if (location) {
              location.name = name;
              break;
            }
          }
        }
      })
      .addCase('locations/locationCreated', (state, action) => {
        const { organization: oldId } = action.payload;
        const affectedOrganizationIsInPage =
          state.organizations &&
          state.organizations.some((org) => org.oldId === oldId);

        if (affectedOrganizationIsInPage) {
          // invalidate the organizations page if a location was added to an org in it
          state.organizations = undefined;
        }
      });
  },
});

export const fetchAllOrganizations = (orgId) => async (dispatch, getState) => {
  dispatch(allOrganizationsLoading());
  try {
    const organizationsState = getState().organizations;
    const organizationId = orgId || organizationsState.currentOrganizationId;
    const result = await axiosClient.get(
      `/api/organizations/getAllWithActiveLocations/${organizationId}`,
      getAuthHeaders()
    );
    const organizations = result.data.data;
    dispatch(allOrganizationsReceived(organizations));
  } catch (error) {
    dispatch(allOrganizationsError(error.message));
  }
};

export const fetchOrganizations = ({
  pageNumber,
  searchTerm,
  sort,
  pageSize,
  organizationId,
} = {}) => async (dispatch, getState) => {
  try {
    const organizationsState = getState().organizations;

    pageSize = pageSize || organizationsState.pageSize;
    pageNumber = pageNumber || organizationsState.pageNumber;
    searchTerm =
      searchTerm !== undefined ? searchTerm : organizationsState.searchTerm;
    sort = sort || organizationsState.sort;
    organizationId = organizationId || organizationsState.currentOrganizationId;

    dispatch(organizationsLoading());
    const result = await axiosClient.post(
      '/api/organizations/getPage',
      {
        organizationId,
        pageNumber,
        pageSize,
        searchTerm,
        sort,
      },
      getAuthHeaders()
    );
    const organizations = result.data.data;
    dispatch(organizationsReceived({ ...organizations, searchTerm, sort }));
  } catch (error) {
    dispatch(organizationsError(error.message));
  }
};

export const organizationCreated = () => async (dispatch, getState) => {
  dispatch(resetOrganizations());
  dispatch(fetchOrganizations());
};

export const {
  allOrganizationsLoading,
  allOrganizationsError,
  allOrganizationsReceived,
  organizationsLoading,
  organizationsError,
  organizationsReceived,
  organizationChanged,
  resetOrganizations,
  setActiveServiceLevel,
  setCurrentOrganization,
  setCurrentScopes,
} = organizationsSlice.actions;
export const { reducer: organizationsReducer } = organizationsSlice;
export default organizationsReducer;
