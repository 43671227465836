import i18n from 'i18next';
import LocizeBackend from 'i18next-locize-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const options = {
  fallbackLng: 'en',
  appendNamespaceToCIMode: true,
  saveMissing: true,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  debug: false,
  keySeparator: '### not used ###', // we use content as keys
  nsSeparator: '### not used ###', // we use content as keys

  backend: {
    projectId: '0e4ba6e0-a896-4f7d-ae3c-0414be1b9350',
    // version: '[VERSION]',
  },

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: function(value, format, lng) {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },

  react: {
    wait: true,
  },

  editor: {
    // trigger a reload on editor save
    onEditorSaved: function(lng, ns) {
      i18n.reloadResources(lng, ns);
    },
  },

  detection: {
    order: ['querystring', 'navigator'],
  },
};

if (process.env.NODE_ENV !== 'production') {
  options.backend.apiKey = '84a7f9cd-2fb8-43ba-bb12-e6d310fa0ae4';
  options.backend.referenceLng = 'en';
}

i18n
  .use(LocizeBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options);

export default i18n;
