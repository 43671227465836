import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isLeftClickEvent, isModifiedEvent } from '../eventUtils';
import './Sidebar.css';

export default class Sidebar extends PureComponent {
  static propTypes = {};
  static defaultProps = {};

  render() {
    return <div className="rpl-Sidebar__root">{this.props.children}</div>;
  }
}

export class SidebarCategory extends PureComponent {
  static propTypes = {
    children: PropTypes.string.isRequired,
  };
  static defaultProps = {};

  render() {
    return (
      <div className="rpl-Sidebar__category">
        <span>{this.props.children.toUpperCase()}</span>
      </div>
    );
  }
}

export class SidebarItem extends PureComponent {
  static propTypes = {
    to: PropTypes.string,
    highlighted: PropTypes.bool,
    onItemClicked: PropTypes.func,
  };
  static defaultProps = {
    highlighted: false,
  };

  handleClick = (event) => {
    if (isModifiedEvent(event) || !isLeftClickEvent(event)) return;

    event.preventDefault();
    this.props.onItemClicked();
  };

  render() {
    const { highlighted, to, children } = this.props;
    return (
      <div className="rpl-Sidebar__item">
        <NavLink
          className={({ isActive }) =>
            classnames({
              'rpl-Sidebar__item-highlighted': to && (isActive || highlighted),
            })
          }
          to={to}
          onClick={this.handleClick}
        >
          {children}
        </NavLink>
      </div>
    );
  }
}
