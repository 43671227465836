// @flow strict
import * as React from 'react';
import { auth } from '../../Auth';
import withNavigate from '../../hoc/withNavigate';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { compose } from 'recompose';
import LoaderPage from '../../lib/layout/LoaderPage';

import * as operations from '../../operations';

type Props = {
  replaceUrl: (string) => void,
  handleAuthentication: () => void,
  error?: string,
};

class AuthorizeHandler extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.handleAuthentication();
  }

  render() {
    const { error } = this.props;
    return !!error ? <div>{error}</div> : <LoaderPage />;
  }
}

const INIT_USER = gql`
  mutation initUser($id: String!) {
    initUser(input: { clientMutationId: "1", id: $id }) {
      clientMutationId
      success
    }
  }
`;

export const LoginCallbackPage = compose(
  withNavigate(),
  graphql(INIT_USER, {
    props: ({ mutate, ownProps: { done, replaceUrl } }) => ({
      handleAuthentication: async () => {
        let userId;
        try {
          const { sub } = await auth.handleAuthentication();
          userId = sub;
        } catch (err) {
          operations.logout();
          return {
            error: err.errorDescription,
          };
        }

        let res;
        try {
          await auth.clearRolesCache();

          res = await mutate({
            variables: {
              id: userId,
            },
          });
          res = res.data.initUser;
        } catch (err) {
          operations.logout();

          return {
            error: err.message,
          };
        }
        if (res.error) {
          operations.logout();
          return {
            error: 'Failed: ' + res.error,
          };
        }
        if (res.success) {
          const redirectTo =
            window.localStorage.getItem('loginRedirect') || '/';
          window.localStorage.removeItem('loginRedirect');

          replaceUrl(redirectTo);
          return {};
        } else {
          operations.logout();
          return {
            error: 'Failed to initialize',
          };
        }
      },
    }),
  })
)(
  ({
    replaceUrl,
    handleAuthentication,
    loading,
    error,
  }: {
    replaceUrl: (string) => void,
    handleAuthentication: () => void,
    loading: boolean,
    error?: string,
  }) => (
    <AuthorizeHandler
      error={error}
      loading={loading}
      auth={auth}
      replaceUrl={replaceUrl}
      handleAuthentication={handleAuthentication}
      key={'rpl-authorize-handler'}
    />
  )
);
