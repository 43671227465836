import Breakjs from 'breakjs';
import React, { PureComponent } from 'react';

// from https://material.io/guidelines/layout/responsive-ui.html#responsive-ui-breakpoints

export const tabletBreak = 'smTablet';
export const desktopBreak = 'md';

const tabletBreakAt = 720;
const desktopBreakAt = 960;

export const layout = Breakjs({
  mobile: 0,
  tablet: tabletBreakAt,
  desktop: desktopBreakAt,
});

export function withBreak() {
  return function(Component) {
    return class ComponentWithBreak extends PureComponent {
      state = { layout: layout.current() };

      onLayoutChange = (layout) => {
        if (this.__mounted) {
          this.setState({ layout: layout });
        }
      };

      componentDidMount() {
        this.__mounted = true;
        layout.addChangeListener(this.onLayoutChange);
      }

      componentWillUnmount() {
        this.__mounted = false;
        layout.removeChangeListener(this.onLayoutChange);
      }

      render() {
        return (
          <Component
            {...this.props}
            isTablet={this.state.layout === 'tablet'}
            isDesktop={this.state.layout === 'desktop'}
          />
        );
      }
    };
  };
}
