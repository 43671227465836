import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';

const DEFAULT_LANG = {
  english_name: 'GENERAL',
  native_name: 'for all languages',
  alpha2: 'N/A',
};

const Query = gql`
  query {
    viewer {
      languageCodes {
        english_name
        native_name
        alpha2
        alpha1
      }
    }
  }
`;

export default graphql(Query, {
  props: ({ ownProps, data: { loading, viewer } }) => {
    let out = { loading };
    if (!loading) {
      const languageCodes = [DEFAULT_LANG, ...viewer.languageCodes].reduce(
        (result, { alpha2: value, native_name, english_name }) => {
          result[value] = `${english_name} (${native_name})`;
          return result;
        },
        {}
      );

      const languageCodesShort = [DEFAULT_LANG, ...viewer.languageCodes].reduce(
        (result, { alpha1: value, native_name, english_name }) => {
          result[value] = `${english_name}`;
          return result;
        },
        {}
      );

      out = {
        languageCodes,
        languageCodesShort,
      };
    }

    return out;
  },
});
