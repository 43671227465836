import { combineReducers } from '@reduxjs/toolkit';
import makersReducer from '../pages/entities/makers/store';
import contentReducer from '../pages/entities/content/store';
import campaignsReducer from '../pages/entities/campaigns/store';
import locationsReducer from '../pages/entities/locations/store';
import hierarchyReducer from '../pages/entities/hierarchy/store';
import organizationsReducer from '../pages/entities/organizations/store';

export default combineReducers({
  makers: makersReducer,
  content: contentReducer,
  campaigns: campaignsReducer,
  locations: locationsReducer,
  hierarchy: hierarchyReducer,
  organizations: organizationsReducer,
});
