// @flow
import * as React from 'react';

import i18n from './i18n';
import { Trans } from 'react-i18next';
import reactStringReplace from 'react-string-replace';

type Props = {
  transkey?: string,
  parent?: string,
  children: React.Node,
};

export const Translate = ({ parent, transkey, children }: Props) => (
  <Trans i18nKey={transkey} parent={parent}>
    {children}
  </Trans>
);

export function t(
  str: string | string[],
  vars?: { [key: string]: string }
): string {
  return i18n.t(str, vars).trim();
}

export function tHTML(
  str: string | string[],
  vars?: { [key: string]: string }
): string {
  let value = t(str, vars);

  for (const key in vars) {
    value = reactStringReplace(value, `{${key}}`, () => vars[key]);
  }

  return value;
}
