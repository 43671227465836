import React from 'react';

export default function CoffeeIcon({ size, fill }) {
  size = size || 15;
  fill = fill || '#000000';
  const cls1 = {
    fill,
    strokeMiterlimit: '10',
    strokeWidth: '0.25px',
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 22 23"
    >
      <path
        style={cls1}
        d="M 5.3083 6.04131C 4.82663 6.04131 4.4577 5.67703 4.4577 5.20145L 4.4577 2.92472C 4.4577 2.44914 4.82663 2.08486 5.3083 2.08486C 5.78996 2.08486 6.1589 2.44914 6.1589 2.92472L 6.1589 5.21157C 6.1589 5.67703 5.78996 6.04131 5.3083 6.04131Z"
      />
      <path
        style={cls1}
        d="M 9.08976 5.95997C 8.6081 5.95997 8.23917 5.5957 8.23917 5.12011L 8.23917 0.83986C 8.23917 0.364277 8.6081 0 9.08976 0C 9.57143 0 9.94036 0.364277 9.94036 0.83986L 9.94036 5.13023C 9.93011 5.5957 9.56118 5.95997 9.08976 5.95997Z"
      />
      <path
        style={cls1}
        d="M 12.8616 5.96036C 12.3799 5.96036 12.011 5.59608 12.011 5.1205L 12.011 2.92472C 12.011 2.44914 12.3799 2.08486 12.8616 2.08486C 13.3433 2.08486 13.7122 2.44914 13.7122 2.92472L 13.7122 5.1205C 13.7122 5.59608 13.3433 5.96036 12.8616 5.96036Z"
      />
      <path
        style={cls1}
        d="M 16.4383 21.3203L 1.73219 21.3203C 1.25053 21.3203 0.881593 21.6846 0.881593 22.1601C 0.881593 22.6357 1.25053 23 1.73219 23L 16.4486 23C 16.9302 23 17.2992 22.6357 17.2992 22.1601C 17.2992 21.6846 16.92 21.3203 16.4383 21.3203Z"
      />
      <path
        style={cls1}
        d="M 18.3647 9.52182L 18.0778 9.52182L 18.0778 8.10518C 18.0778 7.6296 17.7088 7.26532 17.2272 7.26532L 0.850597 7.26532C 0.368934 7.26532 0 7.6296 0 8.10518L 0 10.8069C 0 15.7044 4.05827 19.7114 9.04913 19.7114C 12.3183 19.7114 15.1673 17.9811 16.7455 15.4211L 18.3647 15.4211C 20.0044 15.4211 21.3572 14.0854 21.3572 12.4664C 21.3572 10.8474 19.9942 9.52182 18.3647 9.52182ZM 9.05938 18.0317C 5.0011 18.0317 1.7012 14.7735 1.7012 10.7968L 1.7012 8.93493L 16.3868 8.93493L 16.3868 10.7968C 16.3868 14.7836 13.0869 18.0317 9.05938 18.0317ZM 18.3647 13.7515L 17.5756 13.7515C 17.8626 12.942 18.0265 12.0819 18.088 11.1914L 18.375 11.1914C 19.0821 11.1914 19.6765 11.7783 19.6765 12.4765C 19.6765 13.1747 19.0616 13.7515 18.3647 13.7515Z"
      />
    </svg>
  );
}
