// @flow
import * as React from 'react';
import { PureComponent } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { flowRight as compose } from 'lodash';
import { withLoader, withNavigate } from '../../hoc';
import HomePage from '../../lib/portal/HomePage';
import locations from '../../locations';
import _get from 'lodash/get';
import { AppLayoutContext } from '../../context/AppLayoutContext';

type User = {
  id: string,
  name: string,
  email: string,
};

type RippleMaker = {
  serial: string,
  appVariant: 'default' | 'beer',
};

type Location = {
  id: string,
  name: string,
  address: string,
  rippleMakers: Array<RippleMaker>,
};

type Props = {
  org: string,
  singleUser: User | null,
  singleLocation: Location | null,
  navigate: (url: string) => void,
};

class HomePageContainer extends PureComponent<Props> {
  static contextType = AppLayoutContext;

  onDevicesClicked = () => {
    const { navigate, org } = this.props;
    navigate(locations.org(org).RippleMaker.index.url());
  };

  onChannelsClicked = () => {
    const { navigate, org } = this.props;
    navigate(locations.org(org).Channels.library.url());
  };

  onRipplesClicked = () => {
    const { navigate, org } = this.props;
    navigate(locations.org(org).Ripples.library.url());
  };

  onLocationsClicked = () => {
    const { navigate, org } = this.props;
    navigate(locations.org(org).Locations.index.url());
  };

  onUsersClicked = () => {
    const { navigate, org } = this.props;
    navigate(locations.org(org).Users.index.url());
  };

  render() {
    const {
      permissions,
      singleUser: user,
      singleLocation: location,
    } = this.props;

    return (
      <HomePage
        location={location}
        user={user}
        showContent={getShowContentOption(permissions)}
        onDevicesClicked={this.onDevicesClicked}
        onChannelsClicked={this.onChannelsClicked}
        onRipplesClicked={this.onRipplesClicked}
        onLocationsClicked={this.onLocationsClicked}
        onUsersClicked={this.onUsersClicked}
      />
    );
  }
}

const getShowContentOption = (
  permissions: string[]
): 'all' | 'ripplesOnly' | 'none' => {
  const canViewRipples =
    permissions.includes('ripple:view') ||
    permissions.includes('ripple:upload');
  const canViewChannels = permissions.includes('channel:view');
  const canViewDevices = permissions.includes('location:manage');

  if (canViewChannels && canViewDevices && canViewRipples) {
    return 'all';
  }

  if (canViewRipples) {
    return 'ripplesOnly';
  }

  return 'none';
};

const Query = gql`
  query($org: String!, $withLocation: Boolean!, $withUser: Boolean!) {
    viewer {
      me {
        id
      }
      organizationScope(id: $org) {
        users @include(if: $withUser) {
          edges {
            node {
              id
              name
              email
            }
          }
        }
        locations(first: 1) @include(if: $withLocation) {
          edges {
            node {
              id
              name
              displayedName
              address
              rippleMakers {
                serial
                appVariant
              }
            }
          }
        }
      }
    }
  }
`;

const QueryHandler = {
  props: ({ ownProps, data: { loading, viewer } }) => {
    let out = { loading };
    if (!loading && viewer) {
      let currentUserId = _get(viewer, 'me.id');
      let users = _get(viewer, 'organizationScope.users.edges', []).map(
        ({ node }) => ({
          ...node,
        })
      );

      let locations = _get(viewer, 'organizationScope.locations.edges', [])
        .map(({ node }) => ({ ...node }))
        .map(({ id, name, displayedName, address, rippleMakers }) => ({
          id,
          name: displayedName || name,
          address,
          rippleMakers,
        }));

      let singleUser =
        !!users && users.length > 0
          ? users.find((user) => user.id === currentUserId)
          : null;
      let singleLocation =
        !!locations && locations.length > 0 ? locations[0] : null;

      out = {
        singleUser,
        singleLocation,
      };
    }
    return out;
  },
  options: ({ org, permissions }) => {
    const withUser = permissions.includes('user:admin');
    const withLocation =
      permissions.includes('organization:manage') &&
      permissions.includes('location:manage');
    return {
      variables: {
        org,
        withUser,
        withLocation,
      },
    };
  },
};

export default compose(
  graphql(Query, QueryHandler),
  withLoader(),
  withNavigate()
)(HomePageContainer);
