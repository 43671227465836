import React from 'react';

export default function WarningIcon({ size, fill }) {
  size = size || 15;
  fill = fill || '#000000';

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 4 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.26562 13.999H0.716797V4.48926H3.26562V13.999ZM0.567383 2.02832C0.567383 1.64746 0.693359 1.33398 0.945312 1.08789C1.20312 0.841797 1.55176 0.71875 1.99121 0.71875C2.4248 0.71875 2.77051 0.841797 3.02832 1.08789C3.28613 1.33398 3.41504 1.64746 3.41504 2.02832C3.41504 2.41504 3.2832 2.73145 3.01953 2.97754C2.76172 3.22363 2.41895 3.34668 1.99121 3.34668C1.56348 3.34668 1.21777 3.22363 0.954102 2.97754C0.696289 2.73145 0.567383 2.41504 0.567383 2.02832Z"
        fill="#333333"
      />
    </svg>
  );
}
