import React from 'react';

export default function InviteUserIcon({ size, fill }) {
  size = size || 20;
  fill = fill || '#000000';
  const cls1 = {
    fill,
    strokeMiterlimit: '10',
    strokeWidth: '0.25px',
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 34 40"
    >
      <path
        style={cls1}
        d="M 18.3074 19.1449L 18.5473 19.1449C 20.7065 19.1081 22.4597 18.354 23.7516 16.9012C 26.5936 13.7104 26.123 8.23909 26.0677 7.71495C 25.8831 3.79771 24.0192 1.92184 22.4874 1.03908C 21.3432 0.386207 20.0052 0.0275862 18.5196 0L 18.3904 0C 17.5692 0 15.9636 0.128736 14.4226 1.0115C 12.8724 1.88506 10.9808 3.76093 10.7962 7.70576C 10.7409 8.2299 10.2703 13.7012 13.1123 16.892C 14.395 18.3449 16.1482 19.1081 18.3074 19.1449ZM 12.7617 7.88967C 12.7617 7.87128 12.7709 7.84369 12.7709 7.83449C 13.0108 2.55632 16.7756 1.99541 18.3812 1.99541L 18.4735 1.99541C 20.4666 2.04138 23.8531 2.85058 24.0838 7.83449C 24.0838 7.85288 24.0838 7.88047 24.093 7.88967C 24.1022 7.94484 24.619 12.9471 22.266 15.577C 21.334 16.6253 20.0975 17.1403 18.4643 17.1495L 18.3904 17.1495C 16.7664 17.1311 15.5207 16.6161 14.598 15.577C 12.2542 12.9655 12.7617 7.93564 12.7617 7.88967Z"
      />
      <path
        style={cls1}
        d="M 33.5973 28.2211L 33.5973 28.2027C 33.5973 28.1475 33.588 28.0832 33.588 28.0188C 33.5419 26.5659 33.4496 23.1544 30.2477 22.0694C 30.2292 22.0602 30.1923 22.051 30.1739 22.051C 26.8428 21.205 24.0745 19.2924 24.0468 19.274C 23.5947 18.9613 22.9764 19.0717 22.6627 19.513C 22.349 19.9636 22.4597 20.5797 22.9026 20.8924C 23.0318 20.9843 25.9661 23.0165 29.6387 23.9636C 31.355 24.5705 31.5488 26.4096 31.6041 28.0832C 31.6041 28.1475 31.6041 28.2119 31.6133 28.2671C 31.6226 28.9291 31.5764 29.9498 31.4565 30.5383C 30.2569 31.2188 25.5694 33.5544 18.4458 33.5544C 17.5323 33.5544 16.6556 33.5177 15.8252 33.4441C 15.6775 34.1154 15.4653 34.759 15.1885 35.3751C 16.2127 35.4763 17.3016 35.5315 18.4458 35.5315C 27.3226 35.5315 32.6376 32.1567 32.8591 32.0188C 33.0251 31.9085 33.1728 31.7429 33.2466 31.559C 33.6157 30.5659 33.6065 28.5981 33.5973 28.2211Z"
      />
      <path
        style={cls1}
        d="M 11.0361 22.5381C 12.7617 21.7197 13.8966 20.9197 13.9797 20.8738C 14.4318 20.5611 14.5426 19.9358 14.2196 19.4944C 13.9059 19.0439 13.2784 18.9335 12.8355 19.2554C 12.8078 19.2738 10.9623 20.5519 8.46167 21.4807C 9.37519 21.7197 10.2426 22.0784 11.0361 22.5381Z"
      />
      <path
        style={cls1}
        d="M 7.06823 35.5954C 4.15236 35.5954 1.77167 33.2138 1.77167 30.308C 1.77167 29.4253 2.03004 28.5425 2.38991 27.8437L 1.06116 26.5195C 0.442918 27.669 0 28.9011 0 30.308C 0 34.1793 3.18347 37.3517 7.06823 37.3517L 7.06823 40L 10.6116 36.469L 7.06823 32.9471L 7.06823 35.5954Z"
      />
      <path
        style={cls1}
        d="M 7.18836 25.7471C 10.1042 25.7471 12.4849 28.1287 12.4849 31.0345C 12.4849 31.9173 12.2266 32.8 11.8667 33.4989L 13.1954 34.823C 13.8137 33.6828 14.2566 32.4414 14.2566 31.0345C 14.2566 27.1632 11.0731 23.9908 7.18836 23.9908L 7.18836 21.3425L 3.64502 24.8736L 7.18836 28.3954L 7.18836 25.7471Z"
      />
    </svg>
  );
}
