import { createChangeEmitter } from 'change-emitter';

const scriptWorkerEmitter = createChangeEmitter();
const STATE_UP_TO_DATE = 1;
const STATE_HAS_NEW_VERSION = 2;
const STATE_PRECACHED = 3;
const STATE_OFFLINE = 4;

let state = STATE_UP_TO_DATE;

function setState(newState) {
  if (newState !== state) {
    let previousState = state;
    state = newState;
    scriptWorkerEmitter.emit(previousState, newState);
  }
}

export default {
  getState: () => state,
  listen: (fn) => scriptWorkerEmitter.listen(fn),
  setNewVersionAvailable() {
    setState(STATE_HAS_NEW_VERSION);
  },
  setPrecachedAll() {
    setState(STATE_PRECACHED);
  },
  setOfflineMode() {
    setState(STATE_PRECACHED);
  },
  STATE_UP_TO_DATE: STATE_UP_TO_DATE,
  STATE_HAS_NEW_VERSION: STATE_HAS_NEW_VERSION,
  STATE_PRECACHED: STATE_PRECACHED,
  STATE_OFFLINE: STATE_OFFLINE,
};
