// @flow
import * as React from 'react';
import classnames from 'classnames';
import Spinner from './Spinner';
import ProgressBar from './ProgressBar';
import './LoadingOverlay.css';

type Props = {
  children?: React.Node,
  loading: boolean | number,
  style?: any,
};

export default function LoadingOverlay({
  children,
  loading,
  style = {},
}: Props) {
  const determinate: boolean = typeof loading === 'number';
  const progress: number = typeof loading === 'number' ? loading : 0;
  const isLoading: boolean = loading !== false;

  const showSpinner: boolean = isLoading && !determinate;
  const showProgressBar: boolean = isLoading && determinate;

  return (
    <div
      style={style}
      className={classnames('rpl_LoadingOverlay', { visible: isLoading })}
    >
      {showSpinner && <Spinner />}
      {showProgressBar && (
        <ProgressBar progress={progress} showPreparing={true} />
      )}
      <div
        className={classnames('rpl_LoadingOverlay_mask', {
          visible: isLoading,
        })}
      />
      {children}
    </div>
  );
}
