import React from 'react';

// Create the context with a default value of null
const BreadcrumbsContext = React.createContext(null);

export function BreadcrumbsProvider({ breadcrumbs, children }) {
  return (
    <BreadcrumbsContext.Provider value={breadcrumbs}>
      {children}
    </BreadcrumbsContext.Provider>
  );
}

export function BreadcrumbsConsumer({ children }) {
  return <BreadcrumbsContext.Consumer>{children}</BreadcrumbsContext.Consumer>;
}
