import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_API_URL;
console.log(`Using Base API Url: ${baseURL}`);
if(!baseURL){
  throw Error('** Please set REACT_APP_BASE_API_URL **')
}
const axiosClient = axios.create({
  baseURL,
});

export default axiosClient;