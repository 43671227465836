// @flow
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  to: string,
  className: string,
  onClick?: () => void,
  children: React.Node,
};

export function Link({ to, className, onClick, children }: Props) {
  return (
    <RouterLink className={className} to={to} onClick={onClick}>
      {children}
    </RouterLink>
  );
}
