// @flow
import * as React from 'react';
import { Translate } from '../base/Translate';
import './ProgressBar.css';

type Props = {
  progress: number,
  showPreparing: boolean,
};

export default function ProgressBar({
  progress = 0,
  showPreparing = false,
}: Props) {
  let percentage: number = progress;
  if (percentage > 100) {
    percentage = 100;
  }

  if (percentage < 0) {
    percentage = 0;
  }

  const style = {
    width: `${percentage}%`,
  };

  return (
    <div className="rpl-ProgressBar">
      <div className="rpl-ProgressBar__container">
        <div className="rpl-ProgressBar__progress" style={style} />
        {showPreparing && percentage === 0 && (
          <div className="rpl-ProgressBar__preparing_message">
            <Translate>Preparing...</Translate>
          </div>
        )}
      </div>
    </div>
  );
}
