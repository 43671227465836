import React, { useContext, useEffect } from 'react';
import {
  useParams,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useRoutes,
} from 'react-router-dom';
import AppLayout from '../layout/AppLayout';
import RootPage from '../layout/RootPage';
import HomePageContainer from '../pages/entities/HomePageContainer';
import { LoginCallbackPage } from './auth/LoginCallbackPage';
import { AppLayoutContext } from '../context/AppLayoutContext';

export let currentOrgId = null;

const LoginCallbackPageWithParams = () => {
  const params = useParams();
  const location = useLocation();
  const props = { location, params };
  return <LoginCallbackPage {...props} />;
};

const AppLayoutWithParams = () => {
  const { org } = useParams();
  const navigate = useNavigate();

  useEffect(
    () => {
      const orgMatch = new RegExp('^[0-9a-fA-F]{24}$');
      currentOrgId = org;
      if (!currentOrgId.match(orgMatch)) {
        navigate('/');
      }
    },
    [org, navigate]
  );

  return (
    <AppLayout scope={`org:${org}:`}>
      <Outlet />
    </AppLayout>
  );
};

const HomePageContainerWithParams = () => {
  const { org } = useParams();
  const context = useContext(AppLayoutContext);

  const orgMatch = new RegExp('^[0-9a-fA-F]{24}$');
  const { pathname } = window.location;

  const parts = pathname.split('/').filter((part) => part);
  const isValidPath = !parts.length || (parts[1] && parts[1].match(orgMatch));

  if (isValidPath) {
    return <HomePageContainer org={org} {...context} />;
  } else {
    return (window.location.pathname = `/org/${org}`);
  }
};

const LoadOrgChildren = async (orgId) => {
  if (!orgId.match(/^[0-9a-fA-F]{24}$/)) {
    return <Navigate to="/" />;
  }
  currentOrgId = orgId;
  const routesOrg = await import('./routes_org');
  return routesOrg.default(orgId);
};

const routes = [
  {
    path: '/loginCallback',
    element: <LoginCallbackPageWithParams />,
  },
  {
    path: '/org/:org',
    element: <AppLayoutWithParams />,
    children: [
      {
        index: true,
        element: <HomePageContainerWithParams />,
      },
    ],
    handle: {
      lazyChildren: (orgId) => {
        return LoadOrgChildren(orgId);
      },
    },
  },
  {
    path: '*',
    element: <RootPage />,
  },
];

export default routes;
