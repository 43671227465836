import { createContext, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/**
 * Provide a way to inject navigation functions.
 * A navigation function will be provided using a property and expects the params to be filled
 * @return {Function}
 */

export default function withNavigate() {
  return function(Component) {
    return function(oProps) {
      const navigate = useNavigate();
      const location = useLocation();
      const params = useParams();
      const props = { ...oProps, params, location };

      const navigateTo = (url, query) => navigate(`${url}${query || ''}`);

      const replaceUrl = (url) => navigate(url, { replace: true });

      const navigatorLocation = {
        path: location.pathname,
        params,
      };

      return (
        <Component
          {...props}
          navigate={navigateTo}
          replaceUrl={replaceUrl}
          navigatorLocation={navigatorLocation}
        >
          {props.children}
        </Component>
      );
    };
  };
}
