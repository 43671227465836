import React from 'react';
import Spinner from '../Spinner';
import './LoaderPage.css';

/**
 * A page that just displays a loader before all the content loads
 */
export default function LoaderPage() {
  return (
    <div className="rpl-LoaderPage">
      <Spinner large />
    </div>
  );
}
