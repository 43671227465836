// @flow
import { notify } from './airbrake';
import { t } from './base/Translate';
import type { NotificationsDispatcher } from './lib/portal/NotificationsDispatcher';
import notification from 'antd/es/notification';

export function operationSuccessful(notification: {
  title?: string,
  msg: string,
}) {
  const { msg, title } = notification;
  notifiy({
    type: 'success',
    title: title || t('Success!'),
    msg,
  });
}

export function operationWithWarning(notification: {
  title?: string,
  msg: string,
}) {
  const { msg, title } = notification;
  notifiy({
    type: 'warning',
    title: title || t('Attention!'),
    msg,
  });
}

export function operationWithError(notification: {
  title?: string,
  msg: string,
  error?: any,
}) {
  let { error, title, msg } = notification;
  let shouldReportError = true;

  let errorType = null;
  if (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors.length &&
    error.graphQLErrors[0].type
  ) {
    errorType = error.graphQLErrors[0].type;
    msg = error.graphQLErrors[0].message || msg;
    if (errorType === 'UserError') {
      shouldReportError = false;
    }
  }

  notifiy({
    type: 'error',
    title: title || t(['f7b5cc26-542f-40d4-b8b9-9c13ad9bd8b7', 'Oops..!']),
    msg,
  });
  if (error && shouldReportError) notify(error);
}

export const notificationsDispatcher: NotificationsDispatcher = {
  operationSuccessful,
  operationWithWarning,
  operationWithError,
};

type NotifyProps = {
  type: 'success' | 'error' | 'warning',
  title: string,
  msg: string,
};

const notifiy = ({ type, msg, title }: NotifyProps) => {
  if (!notification[type]) {
    throw new Error(
      `wrong notification type sent: ${type} - should be one of 'success' | 'error' | 'warning'`
    );
  }

  notification[type]({
    message: title,
    description: msg,
    placement: 'bottomRight',
  });
};
