// @flow
import * as React from 'react';
import { AuthConsumer, auth } from '../Auth';

import * as operations from '../operations';

export function withLoginState() {
  return function<Props: { allowSignUp?: boolean }>(
    Component: React.ComponentType<Props>
  ): React.ComponentType<
    $Diff<Props, { isLoggedIn: boolean | void, login: void }>
  > {
    return class ComponentWithUserState extends React.PureComponent<
      Props,
      { isLoggedIn: boolean }
    > {
      performLogin = () => {
        const { allowSignUp } = this.props;
        auth.login({ allowSignUp });
      };

      render() {
        let userProps = {
          isLoggedIn: auth.isAuthenticated(),
        };
        return (
          <Component {...this.props} {...userProps} login={this.performLogin} />
        );
      }
    };
  };
}

export function withLogoutAction() {
  return function(Component: React.ComponentType<{}>) {
    return (props: {}) => (
      <AuthConsumer>
        {(auth) => (
          <Component
            {...props}
            logout={() => {
              operations.logout();
            }}
          />
        )}
      </AuthConsumer>
    );
  };
}

export default { withLoginState, withLogoutAction };
