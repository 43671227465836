import React, { PureComponent } from 'react';

export default class SidebarIdentity extends PureComponent {
  static propTypes = {};
  static defaultProps = {};

  render() {
    return <div>{this.props.children}</div>;
  }
}
