// @flow strict
import React, { useEffect, useRef, useState } from 'react';
import qs from 'qs';
import debounce from 'lodash/debounce';

import { getOrganizationByOrgId } from '../../operations/orgDetails';
import SearchIcon from '../img/SearchIcon';
import { Link } from '../base/Link';
import { t } from '../../base/Translate';
import { getAuthHeaders } from '../../Auth';
import './ScopePicker.css';
import axiosClient from '../../helpers/axiosClient';

interface Props {
  setCurrentOrganization: () => {};
  onToggleScopesClick: () => {};
  mainOrganization: string;
}

const ScopePicker = (props: Props) => {
  const [filter, setFilter] = useState('');
  const [scopes, setScopes] = useState([]);
  const [organization, setOrganization] = useState();

  const [currPage, setCurrPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const listRef = useRef();

  const {
    mainOrganization,
    onToggleScopesClick,
    setCurrentOrganization,
  } = props;

  useEffect(
    () => {
      if (mainOrganization) {
        fetchMainOrganization();
      }
    },
    [mainOrganization]
  );

  useEffect(() => {
    if (mainOrganization) {
      fetchOrganizations(true);
    }
  }, []);

  useEffect(
    () => {
      if (mainOrganization && !isLastPage && currPage !== 1) {
        fetchOrganizations(false, filter);
      }
    },
    [currPage]
  );

  const fetchMainOrganization = async () => {
    const organization = await getOrganizationByOrgId(mainOrganization);
    setOrganization(organization);
  };

  const fetchOrganizations = async (isFiltering, filter) => {
    try {
      const result = await axiosClient.get(
        `/api/organizations/orgSwitcher/${mainOrganization}?${qs.stringify({
          filter,
          page: currPage,
          limit: 50,
        })}`,
        getAuthHeaders()
      );
      const organizations = result.data.data;

      if (isFiltering) {
        setScopes(organizations);
        if (listRef) listRef.current.scrollTo(0, 0);
      } else {
        setScopes([...scopes, ...organizations]);
      }
    } catch (error) {
      if (isFiltering) {
        setScopes([]);
      } else {
        setIsLastPage(true);
      }
      // if (error && error.response.status === 404) {
      //   if (isFiltering) {
      //     setScopes([]);
      //   } else {
      //     setIsLastPage(true);
      //   }
      // }
    }
  };

  const handleFilterChange = (e) => {
    e.persist();
    resetPaginate();
    setFilter(e.target.value);
    debounceSearch(e.target.value);
  };

  const debounceSearch = useRef(
    debounce((value) => {
      fetchOrganizations(true, value);
    }, 500)
  ).current;

  useEffect(
    () => {
      return () => {
        debounceSearch.cancel();
      };
    },
    [debounceSearch]
  );

  const resetPaginate = () => {
    setCurrPage(1);
    setIsLastPage(false);
  };

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  return (
    <div className="rpl-ScopePicker__root">
      <div className="rpl-ScopePicker__search">
        <input
          placeholder={t(['520728a1-66d6-4b93-85ea-a9715758a304', 'Search'])}
          //ref={this.filterEl} // TODO: fix
          className="rpl-ScopePicker__search-input"
          onChange={handleFilterChange}
          value={filter}
        />
        <SearchIcon />
      </div>
      <div
        className="rpl-ScopePicker__body"
        onScroll={handleScroll}
        ref={listRef}
      >
        {organization && (
          <div className="rpl-ScopePicker__group">
            <Link
              onClick={onToggleScopesClick}
              className="rpl-ScopePicker__item"
              to={`/org/${organization.id}`}
            >
              {organization.name}
            </Link>
          </div>
        )}
        {scopes.length > 0 && (
          <div className="rpl-ScopePicker__group">
            {scopes.map((scope) => (
              <Link
                onClick={onToggleScopesClick}
                className="rpl-ScopePicker__item"
                key={scope.id}
                to={`/org/${scope.id}`}
              >
                {scope.name}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ScopePicker;
