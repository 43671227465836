// @flow
import * as React from 'react';
import classnames from 'classnames';
import './HomePage.css';

import RippleMakerChip from './RippleMakerChip';
import LocationIcon from '../img/LocationIcon';
import InviteUserIcon from '../img/InviteUserIcon';

import RIPPLES from './homepage-assets/ripples';
import CHANNELS from './homepage-assets/channels';
import DEVICES from './homepage-assets/devices';
import { Translate, t } from '../../base/Translate';

type RippleMaker = {
  serial: string,
  appVariant: 'default' | 'beer',
};

type Location = {
  name: string,
  address: string,
  rippleMakers: Array<RippleMaker>,
};

type User = {
  name: string,
  email: string,
};

type ClickListener = () => any;

type Props = {
  location: Location | null,
  user: User | null,
  showContent: 'all' | 'ripplesOnly' | 'none',
  onDevicesClicked: ClickListener,
  onChannelsClicked: ClickListener,
  onRipplesClicked: ClickListener,
  onLocationsClicked: ClickListener,
  onUsersClicked: ClickListener,
};

const HomePage = (props: Props) => {
  const {
    location,
    user,
    showContent,
    onDevicesClicked,
    onChannelsClicked,
    onRipplesClicked,
    onLocationsClicked,
    onUsersClicked,
  } = props;

  return (
    <div className="rpl-HomePage">
      <div className="rpl-HomePage__container">
        <Jumbotron />
        {showContent !== 'none' && (
          <ContentPane
            ripplesOnly={showContent === 'ripplesOnly'}
            onDevicesClicked={onDevicesClicked}
            onChannelsClicked={onChannelsClicked}
            onRipplesClicked={onRipplesClicked}
          />
        )}
        {location && (
          <LocationsPane
            location={location}
            onLocationsClicked={onLocationsClicked}
          />
        )}
        {user && <UsersPane user={user} onUsersClicked={onUsersClicked} />}
      </div>
    </div>
  );
};

export default HomePage;

const Jumbotron = () => (
  <div className="rpl-HomePage__jumbotron">
    <Translate transkey={'rpl-HomePage__jumbotron-title'}>
      <p>
        LET’S CREATE YOUR
        <br />
        NEXT HAPPY CUSTOMER!
      </p>
    </Translate>
  </div>
);

const ContentPane = ({
  ripplesOnly,
  onDevicesClicked,
  onChannelsClicked,
  onRipplesClicked,
}: {
  ripplesOnly: boolean,
  onDevicesClicked: ClickListener,
  onChannelsClicked: ClickListener,
  onRipplesClicked: ClickListener,
}) => (
  <div className="rpl-HomePage__pane">
    <div className={classnames('rpl-HomePage__pane', 'title')}>
      <Translate>Manage your devices, channels and content</Translate>
    </div>
    <div className="rpl-HomePage__pane-container">
      <div className="rpl-HomePage__pane-container-content-box">
        {!ripplesOnly && (
          <DevicesContentBox onDevicesClicked={onDevicesClicked} />
        )}
        {!ripplesOnly && (
          <ChannelsContentBox onChannelsClicked={onChannelsClicked} />
        )}
        <RipplesContentBox
          bigLayout={ripplesOnly}
          onRipplesClicked={onRipplesClicked}
        />
      </div>
    </div>
  </div>
);

const LocationsPane = ({
  location,
  onLocationsClicked,
}: {
  location: Location,
  onLocationsClicked: ClickListener,
}) => (
  <div className="rpl-HomePage__pane">
    <div className={classnames('rpl-HomePage__pane', 'title')}>
      <Translate>Manage your locations</Translate>
    </div>
    <ContentList
      title={t('Control how your business is shown in our App to customers')}
      action={t('go to locations')}
      onClick={onLocationsClicked}
    >
      <LocationsTable location={location} />
    </ContentList>
  </div>
);

const UsersPane = ({
  user,
  onUsersClicked,
}: {
  user: User,
  onUsersClicked: ClickListener,
}) => (
  <div className="rpl-HomePage__pane">
    <div className={classnames('rpl-HomePage__pane', 'title')}>
      <Translate>Manage your users</Translate>
    </div>
    <ContentList
      title={t('Add users and give them permissions')}
      action={t('go to users')}
      onClick={onUsersClicked}
    >
      <UsersTable user={user} />
    </ContentList>
  </div>
);

const ContentBox = ({
  title,
  action,
  onClick,
  bigLayout = false,
  children,
}: {
  title: string,
  action: string,
  onClick: ClickListener,
  bigLayout?: boolean,
  children: React.Node,
}) => {
  return (
    <div
      className={classnames(
        'rpl-HomePage__content-box',
        bigLayout ? 'big' : null
      )}
    >
      <div className="rpl-HomePage__content-box-title">{title}</div>
      <div className="rpl-HomePage__content-box-children">{children}</div>
      <div className="rpl-HomePage__content-box-action" onClick={onClick}>
        {action}
      </div>
    </div>
  );
};

const ContentList = ({
  title,
  action,
  onClick,
  children,
}: {
  title: string,
  action: string,
  onClick: ClickListener,
  children: React.Node,
}) => {
  return (
    <div className="rpl-HomePage__container-content-list">
      <div className="rpl-HomePage__content-list">
        <div className="rpl-HomePage__content-list-title">{title}</div>
        <div className="rpl-HomePage__content-list-children">{children}</div>
        <div className="rpl-HomePage__content-list-action" onClick={onClick}>
          {action}
        </div>
      </div>
    </div>
  );
};

const DevicesContentBox = ({
  onDevicesClicked,
}: {
  onDevicesClicked: ClickListener,
}) => {
  const items = DEVICES.length > 3 ? DEVICES.slice(0, 3) : DEVICES;
  const styles = [
    { paddingTop: 8, paddingRight: 16 }, // ripple maker
    { paddingRight: 16 }, // mobile
    { paddingTop: 16 }, // tablet
  ];

  return (
    <ContentBox
      title={t('Control Your Devices')}
      action={t('go to ripple maker')}
      onClick={onDevicesClicked}
    >
      {items.map((device, index) => (
        <div
          key={`${index}`}
          className="rpl-HomePage__content-box-devices-item"
          style={styles[index]}
        >
          <img src={device} alt="" />
        </div>
      ))}
    </ContentBox>
  );
};

const ChannelsContentBox = ({
  onChannelsClicked,
}: {
  onChannelsClicked: ClickListener,
}) => {
  const items = CHANNELS.length > 3 ? CHANNELS.slice(0, 3) : CHANNELS;

  return (
    <ContentBox
      title={t('Create Your Content Channel')}
      action={t('go to channels')}
      onClick={onChannelsClicked}
    >
      {items.map((channel, index) => (
        <div
          key={`${index}`}
          className="rpl-HomePage__content-box-channels-item"
        >
          <img src={channel} alt="" />
        </div>
      ))}
    </ContentBox>
  );
};

const RipplesContentBox = ({
  onRipplesClicked,
  bigLayout,
}: {
  onRipplesClicked: ClickListener,
  bigLayout: boolean,
}) => {
  const itemsNumber = bigLayout ? 7 : 4;
  const items =
    RIPPLES.length > itemsNumber ? RIPPLES.slice(0, itemsNumber) : RIPPLES;

  return (
    <ContentBox
      title={t('Upload Your Ripples Content')}
      action={t('go to ripples')}
      bigLayout={bigLayout}
      onClick={onRipplesClicked}
    >
      {items.map((ripple, index) => (
        <div
          key={`${index}`}
          className={classnames(
            'rpl-HomePage__content-box-ripples-item',
            bigLayout ? 'big' : ''
          )}
        >
          <img src={ripple} alt="" />
        </div>
      ))}
    </ContentBox>
  );
};

const LocationsTable = ({ location }: { location: Location }) => {
  const { name, address, rippleMakers } = location;

  const rippleMakersItems =
    rippleMakers.length > 4 ? rippleMakers.slice(0, 4) : rippleMakers;

  return (
    <table className="rpl-HomePage__table">
      <tbody>
        <tr className="rpl-HomePage__table-headers">
          <th>
            <Translate>Location Name</Translate>
          </th>
          <th>
            <Translate>Address</Translate>
          </th>
        </tr>
        <tr>
          <td className="rpl-HomePage__table-first-cell">
            <LocationIcon fill="#e86161" size={28} />
            <div className="rpl-HomePage__table-first-cell-content">
              <span className="rpl-HomePage__table-locations-item-name">
                {name}
              </span>
              <div className="rpl-HomePage__table-locations-item-footer">
                {rippleMakersItems.map((rm) => (
                  <div
                    key={rm.serial}
                    className="rpl-HomePage__table-locations-item-footer-chip"
                  >
                    <RippleMakerChip
                      serial={rm.serial}
                      appVariant={rm.appVariant}
                    />
                  </div>
                ))}
              </div>
            </div>
          </td>
          <td>
            <span>{address}</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const UsersTable = ({ user }: { user: User }) => {
  const { name, email } = user;

  return (
    <table className="rpl-HomePage__table">
      <tbody>
        <tr className="rpl-HomePage__table-headers">
          <th>
            <Translate>User Name</Translate>
          </th>
          <th>
            <Translate>Email</Translate>
          </th>
          <th>
            <Translate>Permissions</Translate>
          </th>
        </tr>
        <tr>
          <td className="rpl-HomePage__table-first-cell">
            <div className="rpl-HomePage__table-users-item">
              <InviteUserIcon fill="#e86161" size={28} />
              <div className="rpl-HomePage__table-first-cell-content">
                <span className="rpl-HomePage__table-users-item-name">
                  {name}
                </span>
              </div>
            </div>
          </td>
          <td>
            <span>{email}</span>
          </td>
          <td>
            <span>Change roles</span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
