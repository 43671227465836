import * as moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { getAuthHeaders } from '../../../../Auth';
import axiosClient from '../../../../helpers/axiosClient';

export const campaignsAPI = {
  createCampaign: () => `/api/campaigns`,
  editCampaign: (campaignId: string) => `/api/campaigns/${campaignId}`,
  getAllByOrgId: (orgId: string) => `/api/campaigns/byOrganizationId/${orgId}`,
  removeCampaign: (campaignId: string, orgId: string) =>
    `/api/campaigns/org/${orgId}/id/${campaignId}`,
};

export type Tab = 'all' | 'active' | 'pending' | 'complete';

export interface AppChannel {
  id: string;
  showOnMobile: boolean;
}

export interface Campaign {
  id: string;
  name: string;
  endDate?: Date;
  startDate?: Date;
  locations: string[];
  templateId?: string;
  organization: string;
  overwriteContent: boolean;
  shoutoutId?: string;
  channels: AppChannel[];
  template: Object | null;
  shoutout: Object | null;
}

export type CampaignMutationResult = {
  campaign?: Campaign,
  warningMessages?: string[],
  rejectedLocations?: { message: string, locations: string[] },
  error?: any,
};

export type DateRange = {
  endDate: Date,
  startDate: Date,
};

export interface NewCampaign {
  name: string;
  locations: string[];
  organization: string;
  endDate?: Date;
  startDate?: Date;
  shoutoutId?: string;
  templateId?: string;
  overwriteContent: boolean;
  channels: AppChannel[];
}

interface CampaignState {
  currentTab: Tab;
  endDate: Date | null;
  startDate: Date | null;
  searchTerm: string | null;
  allCampaigns: Campaign[];
  campaignsLoading: boolean;
  activeCampaigns: Campaign[];
  pendingCampaigns: Campaign[];
  selectedCampaigns: Campaign[];
  completedCampaigns: Campaign[];
  campaignsToPreview: Campaign[];
  editingCampaign: Campaign | null;
  noCampaignsInSelectedOrg: boolean;
  displayedCampaign: Campaign | null;
  campaignsApiExceptions: string | null;
}

const initialState: CampaignState = {
  endDate: null,
  startDate: null,
  searchTerm: null,
  allCampaigns: [],
  currentTab: 'active',
  activeCampaigns: [],
  pendingCampaigns: [],
  editingCampaign: null,
  selectedCampaigns: [],
  completedCampaigns: [],
  displayedCampaign: null,
  campaignsLoading: true,
  // preserve original data on search
  campaignsToPreview: [],
  campaignsApiExceptions: null,
  noCampaignsInSelectedOrg: false,
};

// the main goal in all written logic is to minimize api calls
// less calls === better performance === less waiting

const campaignsSlice: CampaignState = createSlice({
  name: 'campaigns',
  initialState,

  // DEFINE ALL PRIMITIVE SETTERS
  reducers: {
    setEndDate(state, action) {
      state.endDate = action.payload;
    },
    setStartDate(state, action) {
      state.startDate = action.payload;
    },
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    setCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    setAllCampaigns(state, action) {
      state.allCampaigns = action.payload;
    },
    setEditingCampaign(state, action) {
      state.editingCampaign = action.payload;
    },
    setCampaignsToPreview(state, action) {
      state.campaignsToPreview = action.payload;
    },
    setActiveCampaigns(state, action) {
      state.activeCampaigns = action.payload;
    },
    setPendingCampaigns(state, action) {
      state.pendingCampaigns = action.payload;
    },
    setCompleteCampaigns(state, action) {
      state.completedCampaigns = action.payload;
    },
    setDisplayedCampaigns(state, action) {
      state.displayedCampaign = action.payload;
    },
    setCampaignsLoading(state, action) {
      state.campaignsLoading = action.payload;
    },
    setSelectedCampaigns(state, action) {
      state.selectedCampaigns = action.payload;
    },
    setCampaignsApiExceptions(state, action) {
      state.campaignsApiExceptions = action.payload;
    },
    setNoCampaignsInSelectedOrg(state, action) {
      state.noCampaignsInSelectedOrg = action.payload;
    },
  },
});

// GET ALL CAMPAIGNS FROM API AND SET PRIMITIVES
export const getAllCampaignsInOrg = (props = {}) => async (
  dispatch,
  getState
) => {
  const { organizations } = getState();
  const { currentOrganizationId } = organizations;
  const { orgId, campaignId } = props;

  // clear campaigns preview
  dispatch(setCampaignsToPreview([]));

  // clear editing campaign
  dispatch(setCurrentEditingCampaign(null));

  // set loader
  dispatch(setCampaignsLoading(true));

  try {
    const result = await axiosClient.get(
      campaignsAPI.getAllByOrgId(currentOrganizationId),
      getAuthHeaders()
    );

    const { data } = result.data;

    const campaigns: Campaign[] = data;

    if (!campaigns || !campaigns.length) {
      // set no campaigns in org to true
      dispatch(setNoCampaignsInSelectedOrg(true));
      // finally, remove loader
      dispatch(setCampaignsLoading(false));
      return;
    }

    campaigns.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

    // find and set all active
    dispatch(findAllActive(campaigns));

    // find and set all pending
    dispatch(findAllPending(campaigns));

    // find and set all complete
    dispatch(findAllComplete(campaigns));

    // set all campaigns
    dispatch(setAllCampaigns(campaigns));

    dispatch(handleSetCurrentTab(getCurrentTab(getState().campaigns)));

    // // set the currentPreview to the list of all campaigns
    // dispatch(setCampaignsToPreview(campaigns));

    // remove api exceptions if any
    dispatch(setCampaignsApiExceptions(''));

    // set no campaigns in org to false
    dispatch(setNoCampaignsInSelectedOrg(false));

    if (campaignId) {
      dispatch(setCurrentEditingCampaign(campaignId));
    }
  } catch (error) {
    if (error && error.message) {
      // set api exception
      dispatch(setCampaignsApiExceptions(error.message));
      // set no campaigns in org to true
      dispatch(setNoCampaignsInSelectedOrg(true));
    }
  } finally {
    // finally, remove loader
    dispatch(setCampaignsLoading(false));
  }
};

const getCurrentTab = ({ activeCampaigns, pendingCampaigns }) =>
  !activeCampaigns.length > 0 && pendingCampaigns.length > 0
    ? 'pending'
    : 'active';

// CREATE NEW CAMPAIGN AND UPDATE LISTS
export const createCampaign = (params: NewCampaign) => async (
  dispatch,
  getState
): Promise<CampaignMutationResult> => {
  try {
    // create campaign using api
    const _campaign = { ...params };
    delete _campaign.template;
    delete _campaign.shoutout;

    if (_campaign.endDate) {
      _campaign.endDate = new Date(_campaign.endDate);
      _campaign.endDate.setUTCHours(23, 59, 59);
    }

    if (_campaign.startDate) {
      _campaign.startDate = new Date(_campaign.startDate);
      _campaign.startDate.setUTCHours(0, 0, 0, 0);
    }

    _campaign.name.trim();

    const result = await axiosClient.post(
      campaignsAPI.createCampaign(),
      { ..._campaign },
      getAuthHeaders()
    );
    // check for errors
    const { error, data } = result.data;
    if (error) {
      return { error };
    }
    // check if campaign returned
    const { campaign, warningMessages, rejectedLocations } = data;
    if (!campaign) {
      return { warningMessages, rejectedLocations };
    }

    // get campaigns state
    const { campaigns } = getState();

    // get the general list of all campaigns
    const { allCampaigns } = campaigns;

    // create an updated list of all campaigns
    const newAllCampaigns = [campaign, ...allCampaigns];

    // sort campaigns list by start date, closest first
    newAllCampaigns.sort(
      (a, b) => new Date(b.startDate) - new Date(a.startDate)
    );

    // find and set all active
    dispatch(findAllActive(newAllCampaigns));

    // find and set all pending
    dispatch(findAllPending(newAllCampaigns));

    // find and set all complete
    dispatch(findAllComplete(newAllCampaigns));

    // set all campaigns
    dispatch(setAllCampaigns(newAllCampaigns));

    // clear search terms, date range and select 'all' tab
    dispatch(clearAllSearchActions());

    // finally, set the currentPreview to the list of all campaigns
    dispatch(setCampaignsToPreview(newAllCampaigns));

    // return result data to caller for user notifications etc...
    return { campaign, warningMessages, rejectedLocations };
  } catch (error) {
    // worst case scenario, return an error
    return { error };
  }
};

// EDIT CAMPAIGN AND UPDATE LISTS
export const editCampaign = (params: Campaign, isStopped = false) => async (
  dispatch,
  getState
): Promise<CampaignMutationResult> => {
  try {
    const _campaign = { ...params };
    delete _campaign.template;
    delete _campaign.shoutout;

    if (_campaign.endDate && !isStopped) {
      _campaign.endDate = new Date(_campaign.endDate);
      _campaign.endDate.setUTCHours(23, 59, 59);
    }

    if (_campaign.startDate) {
      _campaign.startDate = new Date(_campaign.startDate);
      _campaign.startDate.setUTCHours(0, 0, 0);
    }

    const { id: campaignId } = params;
    const result = await axiosClient.put(
      campaignsAPI.editCampaign(campaignId),
      { ..._campaign, campaignId, isStopped },
      getAuthHeaders()
    );

    // check for errors
    const { error, data } = result;
    if (error) {
      return { error };
    }
    // check if campaign returned
    const { campaign, warningMessages, rejectedLocations } = data.data;
    if (!campaign) {
      return { warningMessages, rejectedLocations };
    }

    // get campaigns state
    const { campaigns } = getState();

    // get the general list of all campaigns
    const { allCampaigns } = campaigns;

    // // find previous version of updated campaign in campaigns list
    // const campaignIndex = allCampaigns.findIndex(
    //   (camp) => camp.id === campaign.id
    // );

    const newAllCampaigns = allCampaigns.map((camp) => {
      if (camp.id === campaign.id) {
        return campaign;
      } else {
        return camp;
      }
    });

    // // replace with updated campaign
    // allCampaigns[campaignIndex] = campaign;

    // find and set all active
    dispatch(findAllActive(newAllCampaigns));

    // find and set all pending
    dispatch(findAllPending(newAllCampaigns));

    // find and set all complete
    dispatch(findAllComplete(newAllCampaigns));

    // set all campaigns
    dispatch(setAllCampaigns(newAllCampaigns));

    // clear search terms, date range and select 'all' tab
    dispatch(clearAllSearchActions());

    // finally, set the currentPreview to the list of all campaigns
    dispatch(setCampaignsToPreview(newAllCampaigns));

    dispatch(setCurrentEditingCampaign(campaignId));

    // return result data to caller for user notifications etc...
    return { campaign, warningMessages, rejectedLocations };
  } catch (error) {
    // worst case scenario, return an error
    return { error };
  }
};

// REMOVE CAMPAIGN AND UPDATE LISTS
export const removeCampaign = (campaignId: string, orgId: string) => async (
  dispatch,
  getState
): Promise<CampaignMutationResult> => {
  try {
    const result = await axiosClient.delete(
      campaignsAPI.removeCampaign(campaignId, orgId),
      getAuthHeaders()
    );

    // check for errors
    const { error } = result.data;
    if (error) {
      return { error };
    }

    // get campaigns state
    const { campaigns } = getState();

    // get the general list of all campaigns
    const { allCampaigns } = campaigns;

    // remove from campaigns array
    const newAllCampaigns = allCampaigns.filter(
      (camp) => camp.id !== campaignId
    );

    // find and set all active
    dispatch(findAllActive(newAllCampaigns));

    // find and set all pending
    dispatch(findAllPending(newAllCampaigns));

    // find and set all complete
    dispatch(findAllComplete(newAllCampaigns));

    // set all campaigns
    dispatch(setAllCampaigns(newAllCampaigns));

    // clear search terms, date range and select 'all' tab
    dispatch(clearAllSearchActions());

    // finally, set the currentPreview to the list of all campaigns
    dispatch(setCampaignsToPreview(newAllCampaigns));

    // return result data to caller for user notifications etc...
    return { success: true };
  } catch (error) {
    // worst case scenario, return an error
    return { error };
  }
};

// find active campaigns based on getAllCampaignsInOrg result
export const findAllActive = (campaigns: Campaign[]) => async (
  dispatch,
  getState
) => {
  const now = new Date();
  const activeCampaigns = campaigns.filter((camp) => {
    return (
      camp.locations.length > 0 &&
      moment(now).isAfter(camp.startDate) &&
      (!camp.endDate || moment(now).isBefore(camp.endDate))
    );
  });
  dispatch(setActiveCampaigns(activeCampaigns));
};

// find pending campaigns based on getAllCampaignsInOrg result
export const findAllPending = (campaigns: Campaign[]) => async (
  dispatch,
  getState
) => {
  const now = new Date();
  const pendingCampaigns = campaigns.filter((camp) => {
    return (
      !camp.startDate ||
      moment(camp.startDate).isAfter(now) ||
      (camp.locations.length === 0 &&
        (!camp.endDate || moment(now).isBefore(camp.endDate)))
    );
  });
  dispatch(setPendingCampaigns(pendingCampaigns));
};

// find complete campaigns based on getAllCampaignsInOrg result
export const findAllComplete = (campaigns: Campaign[]) => async (
  dispatch,
  getState
) => {
  const now = new Date();
  const completedCampaigns = campaigns.filter((camp) => {
    return moment(now).isAfter(camp.endDate);
  });
  dispatch(setCompleteCampaigns(completedCampaigns));
};

// find by search term in current tab
export const searchByName = () => (dispatch, getState) => {
  dispatch(applyFilters());
};

// find by date range in current tab
export const searchByDateRange = () => (dispatch, getState) => {
  dispatch(applyFilters());
};

// find by date range in current tab
export const applyFilters = () => (dispatch, getState) => {
  const { campaigns } = getState();
  const { startDate, endDate, searchTerm } = campaigns;
  let iterator = getCurrentCampaignsByTab(campaigns);
  if (searchTerm && typeof searchTerm === 'string') {
    iterator = iterator.filter((camp) =>
      camp.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
  if (startDate && endDate) {
    const format = 'YYYY-MM-DD';
    iterator = iterator.filter((camp) => {
      const filterEndDate = moment(endDate).format(format);
      const filterStartDate = moment(startDate).format(format);
      const campaignEndDate = moment(camp.endDate).format(format);
      const campaignStartDate = moment(camp.startDate).format(format);
      return (
        moment(campaignStartDate).isSameOrAfter(filterStartDate) &&
        moment(campaignEndDate).isSameOrBefore(filterEndDate)
      );
    });
  }
  let toPreview = iterator;
  dispatch(setCampaignsToPreview(toPreview));
};

// set startDate and endDate primitives and search by dateRange
export const setDateRange = ({ startDate, endDate }: DateRange) => (
  dispatch
) => {
  dispatch(setEndDate(endDate));
  dispatch(setStartDate(startDate));
  dispatch(searchByDateRange());
};

// set searchTerm primitive and search by search term
export const setAndFindBySearchTerm = (searchTerm: string) => (dispatch) => {
  dispatch(setSearchTerm(searchTerm));
  dispatch(searchByName());
};

export const handleSetCurrentTab = (tab: Tab) => async (dispatch, getState) => {
  // set new tab selection
  dispatch(setCurrentTab(tab));
  // apply existing filters and set preview
  dispatch(applyFilters());
  // // get campaigns by selected tab
  // const campaignsToPreview = getCurrentCampaignsByTab(campaigns, tab);
  // // set current preview to new tab
  // dispatch(setCampaignsToPreview(campaignsToPreview));
  // // preserve search term is exists
  // dispatch(searchByName());
  // // preserve date range if exist
  // dispatch(searchByDateRange());
};

export const clearAllSearchActions = () => (dispatch) => {
  dispatch(setEndDate(null));
  dispatch(setStartDate(null));
  dispatch(setSearchTerm(null));
  dispatch(setCurrentTab('active'));
};

export const handleCampaignSelection = (id: string) => (dispatch, getState) => {
  const { campaigns } = getState();
  let { selectedCampaigns } = campaigns;
  // if already selected, remove from selection
  if (selectedCampaigns.includes(id)) {
    selectedCampaigns = selectedCampaigns.filter((_id) => _id !== id);
  } else {
    selectedCampaigns.push(id);
  }

  dispatch(setSelectedCampaigns(selectedCampaigns));
};

// get campaigns list based on selected tabs ( in case of some search )
export const getCurrentCampaignsByTab = (
  campaigns: CampaignState,
  tab: Tab | undefined
) => {
  let currentTab;
  // const { campaigns } = getState();

  if (tab) {
    currentTab = tab;
  } else {
    currentTab = campaigns.currentTab;
  }

  switch (currentTab) {
    case 'all':
      return campaigns.allCampaigns;
    case 'active':
      return campaigns.activeCampaigns;
    case 'pending':
      return campaigns.pendingCampaigns;
    case 'complete':
      return campaigns.completedCampaigns;
    default:
      return campaigns.allCampaigns;
  }
};

export const setCurrentEditingCampaign = (campaignId: string | null) => (
  dispatch,
  getState
) => {
  if (!campaignId) {
    dispatch(setEditingCampaign(null));
    return;
  }
  const { campaigns } = getState();
  const campaign = campaigns.allCampaigns.find(
    (camp) => camp.id === campaignId
  );
  dispatch(setEditingCampaign(campaign));
};

export const {
  setEndDate,
  setStartDate,
  setSearchTerm,
  setCurrentTab,
  setAllCampaigns,
  setEditingCampaign,
  setActiveCampaigns,
  setPendingCampaigns,
  setCampaignsLoading,
  setCompleteCampaigns,
  setSelectedCampaigns,
  setDisplayedCampaigns,
  setCampaignsToPreview,
  setCampaignsApiExceptions,
  setNoCampaignsInSelectedOrg,
} = campaignsSlice.actions;
export const { reducer: locationsReducer } = campaignsSlice;
export default locationsReducer;
