// @flow
import * as React from 'react';
import { withLoginState } from '../../hoc/withIdentity';

type Props = {
  allowSignUp?: boolean,
  login: () => Promise<void>,
};

class LoginPage extends React.PureComponent<Props> {
  componentDidMount(): void {
    window.localStorage.setItem(
      'loginRedirect',
      `${window.location.pathname}?${window.location.search}`
    );
    this.props.login();
  }

  render() {
    return null;
  }
}

export default withLoginState()(LoginPage);
