import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withBreak } from '../breaks';
import SearchIcon from '../img/SearchIcon';
import './ActionBar.css';
import WarningIcon from '../img/WarningIcon';
import { t } from '../../base/Translate';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Select } from 'antd';

const { Option } = Select;

const ActionBar = ({ children, search, showWarning, warningText }) => {
  const actions = [];
  let searchInput = search;
  React.Children.forEach(children, (c, idx) => {
    if (c && c.type === Action) {
      let props = {
        ...c.props,
        onClick: () =>
          new Promise((resolve, reject) => {
            c.props.onClick();
            resolve();
          }),
      };
      actions.unshift(<Action key={c.key || idx} {...props} />);
    }
    if (c && c.type === SearchInput) {
      searchInput = <SearchInput {...c.props} key={c.key || idx} />;
    }
  });
  return (
    <div className="rpl-ActionBar">
      <div className="rpl-ActionBar__inner">
        {showWarning && (
          <div className="rpl-ActionBar__warningText_wrapper">
            <span className="rpl-ActionBar__warningText_icon">
              <WarningIcon />
            </span>
            <span className="rpl-ActionBar__warningText_text">
              <t>{warningText}</t>
            </span>
          </div>
        )}
        <div className="rpl-ActionBar__search">{searchInput}</div>
        <div className="rpl-ActionBar__actions">{actions}</div>
      </div>
    </div>
  );
};

export default withBreak()(ActionBar);

export const Action = ({
  label,
  disabled = true,
  selected,
  onClick = () => {},
  children,
  idx,
}) => {
  const fill = disabled ? '#8c8c8c' : '#4F4F4F';
  const iconsWithFill = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      fill,
      size: 52,
    })
  );
  return (
    <div
      className={classnames('rpl-Action__drawer-action', {
        'rpl-Action__drawer-action-disabled': disabled,
        'rpl-Action__drawer-action-selected': !!selected,
      })}
      onClick={() => {
        if (!disabled) onClick(idx);
      }}
    >
      {iconsWithFill}
      <div className="rpl-Action__drawer-action-tooltip">{label}</div>
    </div>
  );
};

Action.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export class SearchInput extends PureComponent {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
  };
  static defaultProps = {
    placeholder: 'Search..',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.defaultValue &&
      !this.props.defaultValue &&
      this.state.value
    ) {
      this.onClear();
    }
  }

  onClear = () => {
    this.setState({ value: '' });
    this.props.onSearch('');
    this.props.onSearchClear();
  };

  onChange = (e) => {
    const value = e.target.value.toLowerCase();
    const textToSearchBy = value.length ? value : '';
    this.setState({ value });
    this.props.onSearch(textToSearchBy.trim());
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onSearchEnterPressed(e.target.value.toLowerCase());
    }
  };

  renderNextAndPrev = () => {
    return (
      <div className="rpl-SearchInput__NextAndPrev_container">
        <div
          className="rpl-SearchInput__NextAndPrev_button"
          onClick={this.props.handlePrevClicked}
        >
          <CaretUpOutlined />
        </div>
        <div
          className="rpl-SearchInput__NextAndPrev_button"
          onClick={this.props.handleNextClicked}
        >
          <CaretDownOutlined />
        </div>
      </div>
    );
  };

  render() {
    const {
      placeholder,
      disabled,
      renderNextAndPrev,
      count,
      totalCount,
      hasSelectOption,
      selectedField,
      setField,
      availableFields,
      selectClassName,
    } = this.props;
    const { value } = this.state;
    return (
      <React.Fragment>
        {hasSelectOption && (
          <Select
            size="small"
            className={selectClassName}
            popupMatchSelectWidth={false}
            value={selectedField}
            onChange={setField}
          >
            {availableFields.map((field) => (
              <Option value={field.name} key={field.name}>
                {t(field.displayName)}
              </Option>
            ))}
          </Select>
        )}
        <div className="rpl-SearchInput__container">
          <input
            disabled={disabled}
            placeholder={placeholder}
            className="rpl-SearchInput__search-input"
            onChange={this.onChange}
            value={value}
            onKeyPress={this.onKeyPress}
          />
          {renderNextAndPrev && (
            <div>
              <span>{count}</span>
              <span>/</span>
              <span>{totalCount}</span>
            </div>
          )}
          {!value && <SearchIcon size={10} />}
          {renderNextAndPrev && this.renderNextAndPrev()}
          {value && (
            <span
              className="rpl-SearchInput__search-clear"
              onClick={this.onClear}
            >
              x{/* <XIcon size={10} /> */}
            </span>
          )}
        </div>
      </React.Fragment>
    );
  }
}
