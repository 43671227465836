import * as React from 'react';
import { t } from '../../base/Translate';
import './WarningBanner.css';

type Props = {
  text: string,
  defaultText: string,
};

class WarningBanner extends React.PureComponent<Props> {
  render() {
    const { text, defaultText } = this.props;

    return (
      <div className="rpl-WarningBanner-container">
        <div className="rpl-WarningBanner">{t([text, defaultText])}</div>
      </div>
    );
  }
}

export default WarningBanner;
