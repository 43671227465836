// @flow strict
import * as React from 'react';
import { WebAuth } from 'auth0-js';
import config from './authConfig';
import type { WebAuthConfig } from 'auth0-js';
import axiosClient from './helpers/axiosClient';

const STORAGE_KEY_ACCESS_TOKEN = 'access_token';
const STORAGE_KEY_ID_TOKEN = 'id_token';
const STORAGE_KEY_EXPIRATION = 'expires_at';
const STORAGE_KEY_TOKEN_EXPIRATION = 'token_expires_at';
const DEFAULT_INACTIVITY_TIMEOUT = 3600000; // ms

export interface Auth {
  +login: (options: { allowSignUp?: boolean }) => any;
  +handleAuthentication: () => any;
  +setSession: (authResult: {
    expiresIn: number,
    accessToken: string,
    idToken: string,
  }) => any;
  +logout: () => Promise<any>;
  +isAuthenticated: () => boolean;
  +getAccessToken: () => string;
  +clearRolesCache: () => Promise<any>;
  +addOnAuthChangedListener: (
    listener: (isAuthenticated: boolean) => void
  ) => () => void;
}

class AuthImpl implements Auth {
  auth0: WebAuth;
  listeners: Array<onAuthChanged> = [];
  _notifyTimerId: number = 0;
  _inactivityTimeout: number;

  constructor(config: WebAuthConfig) {
    this.auth0 = new WebAuth(config);
    this._inactivityTimeout = DEFAULT_INACTIVITY_TIMEOUT;
    this.resetUserActivityTimeout();
    //this._updateOnExpiry();
  }

  /**
   * _inactivityTimeout is initialized with default value, but can be changed if user's config is different
   */
  updateInactivityTimeout(timeout) {
    this._inactivityTimeout = timeout;
    this.resetUserActivityTimeout();
  }

  login({ allowSignUp = false }: { allowSignUp?: boolean }) {
    this.auth0.authorize({
      prompt: 'login',
      allowSignUp,
      domain: config.domain,
      clientID: config.clientID,
      redirectUri: config.redirectUri,
      audience: config.audience,
    });
  }

  handleAuthentication(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          resolve(authResult.idTokenPayload);
        } else if (err) {
          console.error('failed initializing session:', err);
          reject(err);
        } else {
          console.error('failed parsing auth login state params!');
          reject(new Error('Invalid login state params!'));
        }
      });
    });
  }

  setSession(authResult: {
    expiresIn: number,
    accessToken: string,
    idToken: string,
  }) {
    window.localStorage.setItem(
      STORAGE_KEY_ACCESS_TOKEN,
      authResult.accessToken
    );
    window.localStorage.setItem(STORAGE_KEY_ID_TOKEN, authResult.idToken);
    window.localStorage.setItem(
      STORAGE_KEY_TOKEN_EXPIRATION,
      (authResult.expiresIn * 1000 + Date.now()).toString()
    );
    window.localStorage.setItem(
      STORAGE_KEY_EXPIRATION,
      (this._inactivityTimeout + Date.now()).toString()
    );

    this._notifyOnAuthChanged();
    this._updateOnExpiry();
  }

  async logout() {
    window.localStorage.removeItem(STORAGE_KEY_ACCESS_TOKEN);
    window.localStorage.removeItem(STORAGE_KEY_ID_TOKEN);
    window.localStorage.removeItem(STORAGE_KEY_EXPIRATION);
    window.localStorage.removeItem(STORAGE_KEY_TOKEN_EXPIRATION);

    this._clearExpiryTimeout();

    try {
      this.auth0.logout({
        clientID: config.clientID,
        returnTo: config.returnTo,
      });
    } catch (error) {
      window.location = config.returnTo;
    }

    return true;
  }

  _parseExpiry() {
    return JSON.parse(window.localStorage.getItem(STORAGE_KEY_EXPIRATION));
  }

  _parseTokenExpiry() {
    return JSON.parse(
      window.localStorage.getItem(STORAGE_KEY_TOKEN_EXPIRATION)
    );
  }

  _updateOnExpiry() {
    this._clearExpiryTimeout();

    const expiresAt = this._parseExpiry();
    const expiresIn = expiresAt - Date.now();

    if (expiresIn > 0) {
      this._notifyTimerId = window.setTimeout(
        this._notifyOnAuthChanged.bind(this),
        expiresIn
      );
    }
  }

  resetUserActivityTimeout() {
    this._clearExpiryTimeout();

    const expiresAt = this._parseExpiry();
    const isExpired = expiresAt - Date.now();
    if (isExpired < 0) {
      return;
    }

    const tokenExpiresAt = this._parseTokenExpiry();
    let expiresIn = tokenExpiresAt - Date.now();

    if (expiresIn > this._inactivityTimeout) {
      expiresIn = this._inactivityTimeout;
      window.localStorage.setItem(
        STORAGE_KEY_EXPIRATION,
        (this._inactivityTimeout + Date.now()).toString()
      );
    } else {
      window.localStorage.setItem(STORAGE_KEY_EXPIRATION, tokenExpiresAt);
    }

    if (expiresIn > 0) {
      this._notifyTimerId = window.setTimeout(
        this._notifyOnAuthChanged.bind(this),
        expiresIn
      );
    }
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = this._parseExpiry();
    return new Date().getTime() < expiresAt;
  }

  getAccessToken() {
    return window.localStorage.getItem(STORAGE_KEY_ACCESS_TOKEN);
  }

  clearRolesCache(): Promise<any> {
    return axiosClient.get(`/api/auth/clear-cache`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${this.getAccessToken()}`,
      },
    });
  }

  addOnAuthChangedListener(fn: onAuthChanged) {
    this.listeners.push(fn);
    return () => {
      this.listeners.splice(this.listeners.indexOf(fn), 1);
    };
  }

  _notifyOnAuthChanged() {
    const isAuthenticated = this.isAuthenticated();

    for (let fn of this.listeners) {
      fn(isAuthenticated);
    }
  }

  _clearExpiryTimeout() {
    if (this._notifyTimerId > 0) {
      window.clearTimeout(this._notifyTimerId);
      this._notifyTimerId = -1;
    }
  }
  _getRipplesKey(): string {
    return config.ripplesKey;
  }
}

type onAuthChanged = (isAuthenticated: boolean) => void;

export const auth = new AuthImpl(config);

export const getAuthHeaders = () => {
  const token = auth.getAccessToken();
  return {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
};

export const AuthConsumer = ({
  children,
}: {
  children: (auth: Auth) => React.Node,
}) => children(auth);
