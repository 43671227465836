import React from 'react';
import PropTypes from 'prop-types';
import './Spinner.css';
import classnames from 'classnames';
import Logo from './img/Logo';

function Spinner({ type, small, large }) {
  let firstEl = large ? (
    <div>
      <Logo size={100} />
    </div>
  ) : (
    <div />
  );
  return (
    <div className={classnames('rpl-Spinner', { small: small, large: large })}>
      {firstEl}
      <div />
      <div />
    </div>
  );
}

Spinner.propTypes = {
  type: PropTypes.oneOf(['red', 'white']),
  small: PropTypes.bool,
  large: PropTypes.bool,
};

export default Spinner;
