import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import routes from './route';

async function patchRoutes(matches, patch) {
  const orgId = matches[matches.length - 1]?.params.org;
  const leafRoute = matches[matches.length - 1]?.route;
  if (leafRoute?.handle?.lazyChildren) {
    const children = await leafRoute.handle.lazyChildren(orgId);
    patch(leafRoute.id, children);
  }
}

const router = createBrowserRouter(routes, {
  async unstable_patchRoutesOnMiss({ matches, patch }) {
    await patchRoutes(matches, patch);
  },
  async unstable_patchRoutesOnNavigation({ matches, patch }) {
    await patchRoutes(matches, patch);
  },
});

const RoutesProvider = () => <RouterProvider router={router} />;

export default RoutesProvider;
