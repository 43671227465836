import React from 'react';

export default function LocationIcon({ size, fill }) {
  size = size || 15;
  fill = fill || '#000000';
  const cls1 = {
    fill,
    strokeMiterlimit: '10',
    strokeWidth: '0.25px',
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 28 41"
    >
      <path
        style={cls1}
        d="M 13.937 0C 13.9055 0 13.874 0 13.874 0C 13.8426 0 13.8111 0 13.7797 0C 7.39317 0.0932525 2.23364 6.21683 2.23364 12.5269C 2.23364 19.6763 8.55721 27.7582 12.2381 30.9598C 12.2381 30.9598 13.8426 32.2654 13.874 32.2654C 17.1774 29.4678 25.483 20.1736 25.483 12.4958C 25.4515 6.21683 20.292 0.0932525 13.937 0ZM 13.9684 17.345C 10.6022 17.345 7.86508 14.6717 7.86508 11.3457C 7.86508 8.01971 10.5707 5.34648 13.937 5.34648C 17.3032 5.34648 20.0089 8.0508 20.0089 11.3457C 20.0403 14.6717 17.3032 17.345 13.9684 17.345Z"
      />
      <path
        style={cls1}
        d="M 13.9999 41.0003C 7.04716 41.0003 0 39.6637 0 35.8714C 0 33.2292 3.46066 31.4574 7.95952 30.6492C 8.84042 31.5196 9.65839 32.2656 10.3505 32.8873C 5.47414 33.4157 2.61123 34.9389 2.61123 35.8714C 2.61123 36.9904 6.95278 38.4203 13.9999 38.4203C 21.0786 38.4203 25.3887 36.9904 25.3887 35.8714C 25.3887 34.9389 22.3999 33.3847 17.3662 32.8873C 18.0898 32.2345 18.9078 31.4885 19.7887 30.6182C 24.3819 31.3953 27.9999 33.1981 27.9999 35.9025C 28.0314 39.6326 20.9842 41.0003 13.9999 41.0003Z"
      />
    </svg>
  );
}
