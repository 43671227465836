import React from 'react';
import AppVariantIcon from '../AppVariantIcon';

import './RippleMakerChip.css';

const RippleMakerChip = ({ serial, appVariant }) => (
  <div className="rpl-RippleMakerChip__container">
    <AppVariantIcon appVariant={appVariant} size={15} />
    <span className="rpl-RippleMakerChip__text-serial">{serial}</span>
  </div>
);

export default RippleMakerChip;
