import React, { PureComponent } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './Toastr.css';

export default class Toastr extends PureComponent {
  static propTypes = {};
  static defaultProps = {};

  render() {
    return <ToastContainer position="bottom-center" />;
  }
}
