import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SetPageTitleContext = createContext();
const SetMountedPageTitleContext = createContext();

export function PageTitleProvider({ children, onChange }) {
  const [titleElement, setTitleElement] = useState(null);

  const setTitle = (title) => {
    if (onChange) {
      onChange(title);
    }
  };

  const setMountedTitleElement = (el) => {
    if (el && titleElement && process.env.NODE_ENV !== 'production') {
      console.error('Title element already mounted');
    }
    setTitleElement(el || null);
    if (!el) {
      setTitle('');
    }
  };

  useEffect(
    () => {
      if (titleElement) {
        setTitle(titleElement.textContent);
      }
    },
    [titleElement]
  );

  return (
    <SetPageTitleContext.Provider value={setTitle}>
      <SetMountedPageTitleContext.Provider value={setMountedTitleElement}>
        {children}
      </SetMountedPageTitleContext.Provider>
    </SetPageTitleContext.Provider>
  );
}

PageTitleProvider.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
