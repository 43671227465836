import React from 'react';

export default function SearchIcon({ size, fill }) {
  size = size || 20;
  fill = fill || '#717171';
  const cls1 = {
    fill,
    strokeMiterlimit: '10',
    strokeWidth: '0.25px',
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="-1 -1 21 22"
    >
      <path
        style={cls1}
        d="M 14.1194 13.6539C 13.7307 13.2616 13.0975 13.2587 12.7052 13.6475C 12.3129 14.0362 12.31 14.6694 12.6988 15.0617L 14.1194 13.6539ZM 18.2897 20.7039C 18.6784 21.0962 19.3116 21.0991 19.7039 20.7103C 20.0962 20.3216 20.0991 19.6884 19.7103 19.2961L 18.2897 20.7039ZM 15.1818 8.16514C 15.1818 12.131 11.9985 15.3303 8.09091 15.3303L 8.09091 17.3303C 13.1203 17.3303 17.1818 13.2183 17.1818 8.16514L 15.1818 8.16514ZM 8.09091 15.3303C 4.1833 15.3303 1 12.131 1 8.16514L -1 8.16514C -1 13.2183 3.06155 17.3303 8.09091 17.3303L 8.09091 15.3303ZM 1 8.16514C 1 4.19931 4.1833 1 8.09091 1L 8.09091 -1C 3.06155 -1 -1 3.112 -1 8.16514L 1 8.16514ZM 8.09091 1C 11.9985 1 15.1818 4.19931 15.1818 8.16514L 17.1818 8.16514C 17.1818 3.112 13.1203 -1 8.09091 -1L 8.09091 1ZM 12.6988 15.0617L 18.2897 20.7039L 19.7103 19.2961L 14.1194 13.6539L 12.6988 15.0617Z"
      />
    </svg>
  );
}
